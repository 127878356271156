import tw from "twin.macro"
import { Link as GatsbyLink } from "gatsby"

import { StyledButton } from "../../styled/Button"

export const Side = tw.aside`fixed z-50 lg:z-40 inset-0 lg:right-auto bg-overlay lg:bg-grey-lightest max-w-full lg:w-1/2 lg:h-screen lg:overflow-x-hidden lg:overflow-y-scroll`
export const Section = tw.section`flex h-full lg:flex-col lg:items-center lg:justify-center`
export const Nav = tw.nav`bg-grey-lightest text-grey-mid p-2 w-75-5 lg:h-full lg:flex lg:flex-col lg:justify-center lg:items-center lg:w-100 max-w-full`
export const Link = tw(GatsbyLink)`inline-block m-6 lg:hidden`
export const UpgradeButtonWrapper = tw.div`m-6 lg:hidden`
export const Button = tw(StyledButton)`px-4`
export const Close = tw.button`px-4 bg-pink h-18 w-18 lg:hidden`
export const Overlay = tw.div`hidden lg:block absolute inset-0 bg-overlay opacity-90 cursor-pointer z-20`
