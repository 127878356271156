import tw from "twin.macro"

import { H2, P } from "../../../styled/Text"
// import { StyledButton } from "../../../styled/Button"
import { Button as StyledButton } from "../../Button/Button"
import { StyledCard } from "../../../styled/Card"
import { StyledInput } from "../../../styled/Forms"
import { StyledLink } from "../../../styled/Link"

export const Card = tw(StyledCard)`mt-13 mb-0 lg:mt-0 px-3 lg:px-18 pt-0 pb-19 lg:py-19 text-left rounded-none lg:rounded-xxl lg:max-w-122`
export const Form = tw.form`-mt-7 lg:mt-0`
export const Title = tw(H2)`mb-2 lg:mb-6 text-lg text-grey-dark hidden lg:block`
export const Content = tw(P)`mx-4 lg:mx-0 mt-3 mb-4 text-xs text-grey-light text-center`
export const Field = tw(StyledInput)`mb-4 lg:mb-2`
export const Button = tw(StyledButton)`block mb-4 px-16 mx-auto`
export const SignIn = tw(P)`text-center mt-4`
export const SignInLink = tw(StyledLink)`normal-case text-base`
