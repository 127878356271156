import React from "react"

import { withFooterSubscribe } from "./withFooterSubscribe"
import { Icon } from "../Icon/Icon"

import { Section, Title, Form, Input, Button } from "./FooterSubscribe.styled"

export const FooterSubscribe = withFooterSubscribe(
  ({ title, data, handleChange, handleSubmit, loading, errors, subscribed }: Props): JSX.Element => (
    <Section>
      {subscribed ? (
        <Title>Thanks for subscribing!</Title>
      ) : (
        <>
          <Title>{title}</Title>
          {errors?.length > 0 && (
            <ul>
              {errors?.map((error, index) => (
                <li key={index}>
                  <p>{error?.message || error}</p>
                </li>
              ))}
            </ul>
          )}
          <Form onSubmit={handleSubmit}>
            <Input type={"email"} placeholder={"Email address"} name={"email"} value={data?.email} onChange={handleChange} required />

            <Button type="submit" disabled={loading}>
              <Icon name={"arrowRight"} size={"ml"} colour={"white"} state={"inbutton"} />
            </Button>
          </Form>
        </>
      )}
    </Section>
  )
)

export interface Props {
  title: string
  data: any
  subscribed: boolean
  loading: boolean
  errors: Array<any>
  handleChange: () => void
  handleSubmit: () => void
}
