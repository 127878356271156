import React, { createContext, useContext, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

export const StripeContext = createContext({ products: null, loading: null, setLoading: null })

export const StripeProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)

  const { allStripeProduct, allStripePlan } = useStaticQuery(graphql`
    query STRIPE_PRODUCTS {
      allStripeProduct {
        edges {
          node {
            id
          }
        }
      }
      allStripePlan {
        edges {
          node {
            id
            amount
            currency
            nickname
            product
          }
        }
      }
    }
  `)
  const products = allStripeProduct?.edges?.map(({ node }) => ({...node, plans: allStripePlan.edges?.filter(plan => plan.node.product == node?.id).map(plan => plan.node)}))

  const values = {
    loading,
    setLoading,
    products,
  }

  return <StripeContext.Provider value={{ ...values }}>{children}</StripeContext.Provider>
}

export const useStripeContext = () => useContext(StripeContext)
