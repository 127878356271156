import React from "react"
import tw from "twin.macro"
import ReactLoading from "react-loading"

const Container = tw.div`fixed top-0 left-0 z-10 min-h-screen w-full bg-grey-lightest flex flex-col items-center justify-center`
const Spinner = tw.div`-mt-16`

export const Loading = ({ loading }): JSX.Element =>
  loading && (
    <Container>
      <Spinner>
        <ReactLoading type={"spin"} color={"#FF007D"} height={80} width={80} />
      </Spinner>
    </Container>
  )
