import tw, { styled } from "twin.macro"

const styles = {
  default: tw`w-full mx-auto px-8`,
  collapse: {
    "<md": tw`px-0 md:px-8`,
    "md>": tw`px-8 md:px-0`,
    "<lg": tw`px-0 lg:px-8`,
    "lg>": tw`px-8 lg:px-0`,
  },
  condense: {
    "<md": tw`px-4 md:px-8`,
  },
  overflow: {
    hidden: tw`overflow-hidden`,
  },
  position: {
    relative: tw`relative`,
  },
  spacing: {
    sm: tw`mb-12`,
    md: tw`mb-10 md:mb-17`,
    lg: tw`mb-10 md:mb-24`,
    xl: tw`mb-18`,
    "2xl": tw`mb-32`,
  },
  topSpacing: {
    md: tw`mt-10 md:mt-17`,
    lg: tw`mt-10 md:mt-24`,
    xl: tw`mt-18`,
    "2xl": tw`mt-32`,
  },
  width: {
    xs: tw`max-w-screen-xs`,
    sm: tw`max-w-screen-sm`,
    md: tw`max-w-screen-md`,
    lg: tw`max-w-screen-lg`,
    xl: tw`max-w-screen-xl`,
    xxl: tw`max-w-screen-xxl`,
    full: tw`max-w-full`,
  },
}

export const StyledContainer = styled.section<{
  condense: string
  collapse: string
  overflow: string
  position: string
  spacing: string
  topSpacing: string
  width: string
}>`
  ${styles.default}
  ${({ condense }) => styles.condense[condense] || null}
  ${({ collapse }) => styles.collapse[collapse] || null}
  ${({ overflow }) => styles.overflow[overflow] || null}
  ${({ position }) => styles.position[position] || null}
  ${({ spacing }) => styles.spacing[spacing] || null}
  ${({ topSpacing }) => styles.topSpacing[topSpacing] || null}
  ${({ width }) => styles.width[width] || null}
`
