import { useCallback, useState } from "react"
import { loadStripe } from "@stripe/stripe-js"

import { useApp } from "./useApp"
import { useFunctions } from "./useFunctions"
import { useKlaviyo } from "./useKlaviyo"
import { useStripeContext } from "../providers/stripe"
import { useSettings } from "./useSettings"

export const useStripe = () => {
  const {
    customer,
    config: {
      app: { url },
      services: {
        stripe: { publishKey },
      },
    },
    redirectUrl,
    setRedirectUrl,
  } = useApp()
  const [error, setError] = useState()
  const [subscription, setSubscription] = useState()
  const { loading, setLoading, products } = useStripeContext()
  const {
    membershipCheckout,
    membershipCheckoutSession,
    membershipPortal,
    loading: functionsLoading,
    errors,
    membershipGetSubscription,
    membershipChange,
  } = useFunctions()
  const { memberships } = useSettings()
  const { trackEvent } = useKlaviyo()

  const domain = `https://${typeof window !== "undefined" ? window.location.host : url}`

  const openCheckout = useCallback(
    async (price, type = "", coupon = false) => {
      setLoading(true)
      const stripe = await loadStripe(publishKey)
      const data = await membershipCheckout({
        email: customer?.email || "",
        data: {
          mode: "subscription",
          line_items: [{ price, quantity: 1 }],
          payment_method_types: ["card"],
          ...(coupon && { subscription_data: { coupon } }),
          allow_promotion_codes: !coupon,
          success_url: `${domain}/account/membership/success?session_id={CHECKOUT_SESSION_ID}&plan=${type}`,
          cancel_url: window.location.href, //`${domain}/account/profile`,
          // @ts-ignore-next-line
          ...(window.Rewardful && !!window.Rewardful.referral?.length && { client_reference_id: window.Rewardful.referral }),
        },
      })

      if (data?.status === "success" && data?.body?.id) {
        trackEvent(`Started ${type} Membership`, data?.body)
        setRedirectUrl("")
        await stripe
          .redirectToCheckout({
            sessionId: data?.body?.id,
          })
          .then(result => {
            console.log(result.error.message)
          })
      } else {
        setError(data?.body)
        setLoading(false)
      }
    },
    [membershipCheckout, customer, domain, redirectUrl]
  )

  const openCorporateCheckout = useCallback(
    async (email, price, type = "", coupon = false) => {
      setLoading(true)
      const stripe = await loadStripe(publishKey)
      const data = await membershipCheckout({
        email: email || "",
        data: {
          mode: "subscription",
          line_items: [{ price, quantity: 1 }],
          payment_method_types: ["card"],
          ...(coupon && { subscription_data: { coupon } }),
          allow_promotion_codes: !coupon,
          success_url: `${domain}/corporate/success?session_id={CHECKOUT_SESSION_ID}&plan=${type}`,
          cancel_url: `${domain}/corporate/cancelled`,
          // @ts-ignore-next-line
          ...(window.Rewardful && !!window.Rewardful.referral?.length && { client_reference_id: window.Rewardful.referral }),
        },
      })

      if (data?.status === "success" && data?.body?.id) {
        trackEvent(`Started ${type} Membership`, data?.body)
        setRedirectUrl("")
        await stripe
          .redirectToCheckout({
            sessionId: data?.body?.id,
          })
          .then(result => {
            console.log(result.error.message)
          })
      } else {
        setError(data?.body)
        setLoading(false)
      }
    },
    [membershipCheckout, customer, domain, redirectUrl]
  )

  const openPortal = useCallback(async () => {
    setLoading(true)
    const { url } = await membershipPortal({
      email: customer?.email,
      data: {
        return_url: window.location.href, //`${domain}/account/profile`,
      },
    })

    trackEvent("Viewed Portal", { email: customer?.email })

    if (typeof window !== "undefined" && url) window.location = url
  }, [membershipPortal, customer, domain, url])

  const getCheckoutSession = useCallback(
    async sessionId => {
      if (!functionsLoading) {
        const data = await membershipCheckoutSession({ sessionId })
      }
    },
    [membershipCheckout, functionsLoading]
  )

  const plans = memberships?.edges
    ?.map(({ node }) => ({
      ...node,
      id: node?.slug?.current,
      product: products?.filter(({ id }) => [node?.stripeProduct, node?.stripeProductTest].includes(id))?.[0],
    }))
    ?.map(item => ({
      ...item,
      product: {
        ...item?.product,
        plan: item?.product?.plans?.filter(({ id }) => [item?.stripePrice, item?.stripePriceTest].includes(id))?.[0],
      },
    }))
    .reduce((obj, item) => {
      obj[item.id] = item
      return obj
    }, {})

  const getSubscription = async customerEmail => {
    if (!functionsLoading) {
      const data = await membershipGetSubscription({ customerEmail })
      setSubscription(data?.body)
    }
  }

  const changeMembership = async (currentMembershipId, targetMembershipPrice) => {
    setLoading(true)
    const data = await membershipChange({ currentMembershipId, targetMembershipPrice })
    await getSubscription(customer?.email)
    setLoading(false)
  }

  return {
    error,
    loading,
    plans,
    products,
    subscription,
    openPortal,
    openCheckout,
    openCorporateCheckout,
    getCheckoutSession,
    getSubscription,
    changeMembership,
  }
}
