import React from "react"

import { useMeta } from "../../hooks/useMeta"

export const withMeta = Component => ({ name = "Meta", breadcrumbs = [], data, routes, url }) => {
  const { getData, getLanguages, getSchemas, getTags, getTracking } = useMeta()

  const languages = getLanguages(url)
  const metadata = getData({
    data,
    breadcrumbs,
    language: languages?.find(({ primary }) => primary)?.language,
    routes,
    template: data?.template?.metadata,
    url,
  })
  const metatags = [...getTags(metadata), { name: "facebook-domain-verification", content: "8wl1z3qaenhl5fx33mj9jw3zy01hio" }]
  const schemas = getSchemas(metadata)
  const tracking = getTracking()

  Component.displayName = name
  return (
    <Component languages={languages} metatags={metatags} metadata={metadata} schemas={schemas} tracking={tracking}>
      {tracking.map(script => script)}
    </Component>
  )
}
