import { useContext, useEffect } from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"

import { SearchContext } from "../providers/search"
import { useApp } from "./useApp"
import { useSanityMentor } from "./useSanity"
import { useImage } from "./useImage"
import { useKlaviyo } from "./useKlaviyo"
import qs from "qs"

export const useSearchMentor = () => {
  const { skills, industries, industriesAll, levels } = useSearchSettings() // availabilities, locations
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const processDeepLink = async value => {
    const state = {
      refinementList: {
        skills: [value],
      },
    }

    const URL = `?${qs.stringify(state)}`

    if (value) navigate(`${routes.MENTOR}/${URL}`)
    if (!value) navigate(`${routes.MENTOR}`)
  }

  const extractValues = (array = [], number = false, key = "title") => [
    ...new Set(
      array
        .filter(item => {
          const value = key ? item?.node[key]?.trim() : item
          return value !== null && value !== undefined
        })
        .map(item => ({
          id:
            item?.node?.id ||
            item?.node?._id ||
            item?._id ||
            item?.node[key]?.trim().toString().toLowerCase() ||
            item.toString().toLowerCase().trim(),
          value: key ? item?.node[key]?.trim() : item,
        }))
        .sort((a: any, b: any) => (number ? a.value - b.value : a.value.localeCompare(b.value)))
    ),
  ]

  const extractSingle = (array = [], number = false, key = "title") => [
    ...new Set(
      array
        .filter(item => {
          const value = key ? item?.node[key]?.trim() : item
          return value !== null && value !== undefined
        })
        .map(item => (key ? item?.node[key]?.trim() : item))
        .sort((a: any, b: any) => (number ? a - b : a.localeCompare(b)))
    ),
  ]

  const settings = {
    skills: extractValues(skills?.edges),
    industries: extractValues(industries?.edges),
    industriesAll: extractValues(industriesAll?.edges),
    levels: extractSingle(levels?.edges?.map(({ node }) => node?.items)[0], true, ""),
  }

  return {
    settings,
    processDeepLink,
  }
}

export const useSearchContent = search => {
  const { loading, setLoading, size, setSize, limit, setLimit, query, setQuery, results, setResults } = useContext(SearchContext)
  const { client } = useSanityMentor()
  const { getGatsbyImageData } = useImage()
  const { trackSearch } = useKlaviyo()

  useEffect(() => {
    setLimit(size)
    processSearch()
  }, [search])

  useEffect(() => {
    processSearch()
  }, [limit])

  const processSearch = async () => {
    setLoading(true)
    const filter = [
      '[_type == "pageFlexible" || _type == "pageGeneric" || _type == "article" || _type == "event" || _type == "hotseat" || _type == "podcast" || _type == "video"]',
      "[title match $query || summary match $query || _type match $query || content[].children[].text match $query]",
    ]
    const projection = `{"imageUrl": image.asset->url, "imageAsset": image.asset->,...}`
    const pagination = `[0...${limit}]`
    const queryString = ["*"].concat(filter).concat([pagination]).concat([projection]).join("|")
    const params = {
      query: `*${search}*`,
    }
    client.fetch(queryString, params).then(items => {
      setResults(items?.length ? items.map(normalizeContent) : [])
      trackSearch("Content", search, items?.length)
      setLoading(false)
    })
  }

  const normalizeContent = item => ({
    ...item,
    image: item?.image ? getGatsbyImageData({ ...item?.image, asset: item?.imageAsset || item?.image?.asset }, { maxWidth: 800 }) : null,
  })

  return {
    loading,
    setLoading,
    size,
    setSize,
    limit,
    setLimit,
    query,
    setQuery,
    results,
    setResults,
    processSearch,
  }
}

export const useSearchSettings = () =>
  useStaticQuery(graphql`
    query SANITY_MENTOR_SETTINGS {
      skills: allSanityMentorSkill {
        edges {
          node {
            id: _id
            title
            handle {
              current
            }
          }
        }
      }
      industries: allSanityMentorIndustry(filter: { parentIndustry: { _id: { eq: null } } }) {
        edges {
          node {
            id: _id
            title
            handle {
              current
            }
          }
        }
      }
      industriesAll: allSanityMentorIndustry {
        edges {
          node {
            id: _id
            title
            handle {
              current
            }
          }
        }
      }
      levels: allSanityMentorLevels(filter: { handle: { current: { eq: "default" } } }) {
        edges {
          node {
            items
          }
        }
      }
    }
  `)
