import React from "react"
import "./static/index.css"

import { LocationProvider } from "./src/providers/location"
import { FirebaseProvider } from "./src/providers/firebase"
import { ShopifyProvider } from "./src/providers/shopify"
import { AppProvider } from "./src/providers/app"
import { StripeProvider } from "./src/providers/stripe"
import { SanityProvider } from "./src/providers/sanity"
import { TrackingProvider } from "./src/providers/tracking"
import { SearchProvider } from "./src/providers/search"
import { Layout } from "./src/components/Layout/Layout"

import config from "./config.js"
import { SavedItemsProvider } from "./src/providers/savedItems"

export const wrapRootElement = ({ element }) => (
  <LocationProvider config={config}>
    <ShopifyProvider
      config={{
        shopName: config?.services?.shopify?.defaultShopName,
        apiVersion: config?.services?.shopify?.apiVersion,
        accessToken: config?.stores[config?.services?.shopify?.defaultShopName]?.accessToken,
      }}
    >
      <FirebaseProvider config={config?.services?.firebase}>
        <AppProvider config={config}>
          <SanityProvider>
            <StripeProvider>
              <TrackingProvider>
                <SavedItemsProvider>
                  <SearchProvider>{element}</SearchProvider>
                </SavedItemsProvider>
              </TrackingProvider>
            </StripeProvider>
          </SanityProvider>
        </AppProvider>
      </FirebaseProvider>
    </ShopifyProvider>
  </LocationProvider>
)

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props} settings={config.settings}>
    {element}
  </Layout>
)
