import { useApp } from "./useApp"

export const useRoutes = () => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const findRoute = type => {
    const route = Object.entries(routes)?.find(([key]) => type?.toLowerCase()?.replace("pageaccount", "")?.includes(key?.toLowerCase())) || [
      `page`,
      routes[`page`],
    ]

    return {
      type: route[0]?.toLowerCase(),
      url: route[1],
    }
  }

  const routeResolver = ({ item, type }: any) => {
    const document = item?.document || item
    const itemType = type || document?.type || document?._type
    const handle = document?.handle || document?.slug?.current
    const route = findRoute(itemType)
    return `${route?.url}${handle ? `/${handle}` : ``}`
  }

  const linkResolver = item => {
    const document = item?.document || item?.page || item
    const type = document?.type || document?._type

    if (!item) return {}
    if (type === `navigationSub`) return { url: null, type: `navigationSub` }
    if (type === `link`) return { url: item.link, external: true }

    const route = findRoute(type)

    return {
      title: item?.title,
      type: route?.type,
      url: routeResolver({ item: item[route?.type] || item, type: route?.type }),
    }
  }

  return {
    findRoute,
    routeResolver,
    linkResolver,
  }
}
