import tw, { styled } from "twin.macro"

const styles = {
  state: {
    default: tw``,
    fixed: tw`fixed md:z-50 inset-x-0 top-0`,
    basic: tw`bg-transparent lg:bg-white`,
  },
}

export const StyledHeader = styled.header<{
  fixed: boolean
  basic: boolean
}>`
  ${tw`relative w-full px-4 md:px-0 bg-white z-40`}
  ${({ fixed }) => (fixed ? styles.state.fixed : styles.state.default)}
  ${({ basic }) => (basic ? styles.state.basic : styles.state.default)}
`
