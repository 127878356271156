import tw, { styled } from "twin.macro"

const styles = {
  default: {
    headings: tw`font-sans text-grey-dark font-bold mb-10 leading-tight`,
    text: tw`font-sans text-grey-mid`,
  },
}

export const H1 = styled.h1`
  ${styles.default.headings}
  ${tw`lg:text-2xl text-xl font-black`}
`

export const H2 = styled.h2`
  ${styles.default.headings}
  ${tw`lg:text-2xl text-xl`}
`

export const H3 = styled.h3`
  ${styles.default.headings}
  ${tw`lg:text-xl text-lg`}
`

export const H4 = styled.h4`
  ${styles.default.headings}
  ${tw`lg:text-lg text-md`}
`

export const H5 = styled.h5`
  ${styles.default.headings}
  ${tw`lg:text-sm text-base`}
`

export const H6 = styled.h6`
  ${styles.default.headings}
  ${tw`lg:text-base text-xs`}
`

export const P = styled.p`
  ${styles.default.text}
  ${tw`text-base`}
`

export const Small = styled.p`
  ${styles.default.text}
  ${tw`text-sm`}
`

export const Label = styled.p`
  ${styles.default.text}
  ${tw`text-xs`}
`
