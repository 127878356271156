import { useStaticQuery, graphql } from "gatsby"

export const useTemplate = () => {
  const { event, podcast, article, hotseat, mentor } = useStaticQuery(graphql`
    query SANITY_TEMPLATE {
      global: sanityTemplateGlobal {
        title
        description
      }
      event: sanityTemplateEvent {
        back: additionalBack
        register: additionalRegister
        about: additionalAbout
        callToActionTitle: additionalCallToActionTitle
        callToActionSubtitle: additionalCallToActionSubtitle
        aboutHost: additionalAboutHost
        sponsor: additionalSponsor
        relatedTitle: additionalRelatedTitle
        relatedAll: additionalRelatedAll
        registerTitle: additionalRegisterTitle
        registerSubtitle: additionalRegisterSubtitle
        registerButton: additionalRegisterButton
        registerFirstName: additionalRegisterFirstName
        registerLastName: additionalRegisterLastName
        registerEmail: additionalRegisterEmail
        registeredTitle: additionalRegisteredTitle
        registeredContent: additionalRegisteredContent
        registeredIcon: additionalRegisteredIcon
        registeredMemberCount: additionalRegisteredMemberCount
        registeredMembers: additionalRegisteredMembers
        attend: additionalAttend
        view: additionalView
        callToActionLink: _rawSettingCallToActionLink(resolveReferences: { maxDepth: 10 })
        showRelated: settingShowRelated
      }
      podcast: sanityTemplatePodcast {
        back: additionalBack
        listen: additionalListen
        about: additionalAbout
        aboutHost: additionalAboutHost
        sponsor: additionalSponsor
        relatedTitle: additionalRelatedTitle
        relatedAll: additionalRelatedAll
        showRelated: settingShowRelated
      }
      article: sanityTemplateArticle {
        relatedTitle: additionalRelatedTitle
        relatedAll: additionalRelatedAll
        showRelated: settingShowRelated
      }
      hotseat: sanityTemplateHotseat {
        callToAction: additionalCallToAction
        callToActionSubtitle: additionalCallToActionSubtitle
        callToActionMore: additionalCallToActionMore
        submit: additionalSubmit
        about: additionalAbout
        ideaTitle: additionalIdeaTitle
        ideaSubtitle: additionalIdeaSubtitle
        callToActionLink: _rawSettingsCallToActionLink(resolveReferences: { maxDepth: 10 })
        ideaLink: _rawSettingsIdeaLink(resolveReferences: { maxDepth: 10 })
      }
      mentor: sanityTemplateMentor {
        career: additionalCareer
        careerHistoryHide: additionalCareerHistoryHide
        careerHistoryShow: additionalCareerHistoryShow
        contact: additionalContact
        facts: additionalFacts
        feedbackTitle: additionalFeedbackTitle
        mantra: additionalMantra
        message: additionalMessage
        proud: additionalProud
        related: additionalRelated
        relatedAll: additionalRelatedAll
        request: additionalRequest
        requestNext: additionalRequestNext
        requestLast: additionalRequestLast
        requestStep: additionalRequestStep
        requestAdditional: additionalRequestAdditional
        requestMeetup: additionalRequestMeetup
        requestSkills: additionalRequestSkills
        requestAim: additionalRequestAim
        requestAimPlaceholder: additionalRequestAimPlaceholder
        requestConnect: additionalRequestConnect
        requestAddress: additionalRequestAddress
        requestAddressPlaceholder: additionalRequestAddressPlaceholder
        requestDate: additionalRequestDate
        requestDatePlaceholder: additionalRequestDatePlaceholder
        requestSubmitted: additionalRequestSubmitted
        requestSubmittedSubtitle: additionalRequestSubmittedSubtitle
        requestSubmittedSocial: additionalRequestSubmittedSocial
        requestTipsTitle: additionalRequestTipsTitle
        requestMentorsLink: _rawSettingRequestMentorsLink(resolveReferences: { maxDepth: 10 })
        requestPrepareLink: _rawSettingRequestPrepareLink(resolveReferences: { maxDepth: 10 })
        seeking: additionalSeeking
        skills: additionalSkills
        social: additionalSocial
        spots: additionalSpots
        spotsPlural: additionalSpotsPlural
        wave: additionalWave
        waved: additionalWaved
      }
    }
  `)

  return { global, event, podcast, article, hotseat, mentor }
}
