import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

import { StyledButton } from "../../styled/Button"
import { StyledRow } from "../../styled/Row"

export { StyledColumn as Column } from "../../styled/Column"
export { StyledResponsive as Responsive } from "../../styled/Responsive"
export { StyledHeader } from "../../styled/Header"

export const Row = styled(StyledRow)`
  ${tw`h-18`}
`
export const StyledLink = styled.button`
  ${tw`p-3 md:p-0 md:w-20 h-full flex items-center justify-center cursor-pointer`}
  ${({ pink }) => pink && tw`bg-pink`}
`
export const LogoLink = styled(Link)`
  ${tw`w-32 md:w-44`}
  ${({ left }) => left && tw`ml-6`}
`
export const Button = tw(StyledButton)`block w-auto py-2 mr-4 xl:mr-8 px-8 lg:text-xs`
