import tw, { styled } from "twin.macro"
import { Link as GatsbyLink } from "gatsby"

export const List = styled.ul`
  ${tw`mb-2 lg:w-full`}
  ${({ inline }) => (inline ? tw`flex items-center justify-center mb-0` : tw`lg:mb-12`)}
`
export const Item = tw.li`text-sm`
export const Link = styled(GatsbyLink)`
  ${tw`block px-6 py-4 text-grey-dark hover:underline`}
  ${({ active }) => active && tw`text-pink`}
  ${({ inline }) => (inline ? tw`px-1 xl:px-4` : tw`lg:px-0 lg:text-xl lg:text-2xl lg:font-bold lg:leading-tight`)}
`
