import React, { useState, useLayoutEffect } from "react"

import { isBrowser, useScrollPosition, useScrollTo } from "../../hooks/useScroll"
import { Icon } from "../Icon/Icon"
import { Button } from "./FooterBackToTop.styled"

export const FooterBackToTop = ({ footer }: Props): JSX.Element => {
  const [active, setActive] = useState(false)
  const [offsetHeight, setOffsetHeight] = useState(0)
  const offsetThreshold = 400

  useLayoutEffect(() => {
    const handleRedraw = () => {
      const offset = footer.current && footer.current.offsetHeight
      if (offset !== offsetHeight) {
        setOffsetHeight(offset)
      }
    }

    if (!isBrowser) {
      return null
    }

    window.addEventListener("resize", handleRedraw)
    handleRedraw()
    return () => window.removeEventListener("resize", handleRedraw)
  })

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y * -1 > offsetThreshold) {
        setActive(true)
      } else {
        setActive(false)
      }
    },
    [offsetHeight],
    false,
    false
  )

  return (
    <Button active={active} size={`large`} theme={`primary`} colour={`dark`} onClick={() => useScrollTo({ x: 0, y: 0 })}>
      <Icon name={"chevronUp"} size={"m"} colour={"white"} />
    </Button>
  )
}

export interface Props {
  footer: any
}
