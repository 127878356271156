import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

// import { StyledButton } from "../styled/Button"
import { Button as StyledButton } from "../components/Button/Button"
import { StyledResponsive } from "../styled/Responsive"
import { H1, P } from "./Text"

export const StyledPopupTitle = styled(H1)<{
  large: boolean
}>`
  ${tw`flex items-start justify-center md:max-w-130 md:px-12 md:mx-auto text-center mb-2 md:mb-2 text-xl md:text-3xl`}
  ${({ large }) => !large && tw`font-medium md:font-bold text-lg mb-4 md:mb-6`}
`
export const StyledPopupSmallTitle = styled.div`
  ${tw`flex items-start justify-start text-md leading-none text-grey-mid font-normal`}
`
export const StyledPopupP = styled(P)`
  ${tw`md:text-lg text-center`}
`
export const StyledPopupCloseButton = styled(Link)`
  ${tw`ml-6`}
`
export const StyledPopupButton = styled(StyledButton)<{
  large: boolean
}>`
  ${tw`md:min-w-72 inline-block text-center w-72 md:w-auto max-w-full mt-12 md:mt-0`}
  ${({ large }) => large && tw`mt-8 md:mt-10`}
`
export const StyledPopupButtonFull = styled(StyledButton)`
  ${tw`inline-block text-center mt-12 md:mt-0 w-full`}
`
export const StyledPopupContent = styled(StyledResponsive)`
  ${tw`w-full md:w-auto`}
  ${({ full }) => full && tw`md:w-2/3`}
`

export const StyledEmoji = tw.p`text-12xl text-center w-full leading-none`
