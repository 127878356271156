import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

import { H3, P } from "../../styled/Text"
import { StyledCard } from "../../styled/Card"
import { StyledButton } from "../../styled/Button"

export const Card = styled(StyledCard)`
  ${tw`bg-transparent md:bg-white flex flex-col w-full mb-10 md:rounded-xl md:mb-8 last:mb-0 md:p-5`}
  ${({ mobileSpacing }) => mobileSpacing && tw`mt-14 md:mt-0`}
  ${({ theme }) => theme === `white` && tw`bg-white p-5 pt-8 -mx-5 md:mx-0 w-auto md:w-full`}
`
export const EditLink = tw.button`flex justify-center items-center self-end text-grey-light hover:text-grey-dark -mb-5 md:mb-0 z-1 cursor-pointer`
export const Content = styled.div`
  ${tw`w-full lg:px-3 lg:pb-3`}
  ${({ theme }) => theme === `white` && tw`pb-2`}
`
export const Title = tw(H3)`text-md md:text-lg mb-6 md:mb-4 lg:text-lg`

export const AddButton = styled(StyledButton)`
  width: max-content;
  position: absolute;
  ${tw`py-1.5 px-5 text-xxs! self-end text-pink border-pink`};
`
