import React from "react"
import FullStory from "react-fullstory"

import { withLayout } from "./withLayout"
import { Meta } from "../Meta/Meta"
import { Header } from "../Header/Header"
import { Footer } from "../Footer/Footer"
import { Loading } from "../Loading/Loading"
import { PopupUpgrade } from "../Popup/PopupUpgrade"
import { PopupCompleteProfile } from "../Popup/PopupCompleteProfile"
import { PopupAlreadyBooked } from "../Popup/PopupAlreadyBooked"
import { PopupVideo } from "../Popup/PopupVideo"

import { Content, Main, Wrapper } from "../../styled/Layout"
import { PopupAwaitingMembership } from "../Customer/Membership/PopupAwaitingMembership"

export const Layout = withLayout(
  ({
    data,
    path,
    children,
    title,
    routes,
    center,
    fill,
    basic,
    fixed,
    hideFooter,
    homepage,
    fullstory,
    loading,
    awaitingUpdate,
    refreshMemberStatus,
  }): JSX.Element => (
    <Wrapper>
      <Meta data={data} url={path} routes={routes} />
      <Content homepage={homepage}>
        <Header title={title} basic={basic} fixed={fixed} />
        <Main center={center} fill={fill}>
          {children}
        </Main>
      </Content>
      <PopupUpgrade />
      <PopupCompleteProfile />
      <PopupAlreadyBooked />
      <PopupVideo />
      {!hideFooter && <Footer />}
      <Loading loading={loading} />
      <FullStory {...fullstory} />
      <PopupAwaitingMembership active={awaitingUpdate} refreshMemberStatus={refreshMemberStatus} />
    </Wrapper>
  )
)
