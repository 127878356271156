import React, { useMemo } from "react"

import { useSettings } from "../../hooks/useSettings"

export const withSocialLinks = Component =>
  React.memo(({ name = "SocialLinks", items, ...props }: any) => {
    const {
      social: { channels },
    } = useSettings()

    const social = channels?.map(item => ({
      ...item,
      icon: item?.title?.toLowerCase(),
    }))

    Component.displayName = name
    return useMemo(() => <Component {...props} items={items || social} />, [channels, items])
  })
