import tw, { styled } from "twin.macro"

const styles = {
  default: tw``,
  position: {
    left: tw`flex items-center justify-start`,
    center: tw`flex items-center justify-center`,
    "center-col": tw`flex flex-col items-center justify-center`,
    right: tw`flex items-center justify-end`,
    stretch: tw`flex flex-col`,
  },
  widths: {
    "w-1/6": tw`w-1/2 md:w-1/3 lg:w-1/6`,
    "w-1/4": tw`w-1/4`,
    "w-1/3": tw`w-1/3`,
    "w-1/2": tw`w-1/2`,
    "w-3/4": tw`w-3/4`,
    "w-1/2 md:w-1/6": tw`w-1/2 md:w-1/6`,
    "w-1/2 md:w-1/5": tw`w-1/2 md:w-1/5`,
    "w-1/2 md:w-1/4": tw`w-1/2 md:w-1/4`,
    "w-1/2 md:w-1/3": tw`w-1/2 md:w-1/3`,
    "w-1/3 md:w-full": tw`w-1/3 md:w-full`,
    "w-2/3 md:w-full": tw`w-2/3 md:w-full`,
    "md:w-1/4": tw`w-full md:w-1/4`,
    "md:w-1/3": tw`w-full md:w-1/3`,
    "md:w-1/2": tw`w-full md:w-1/2`,
    "md:w-1/2 lg:w-1/3": tw`w-full md:w-1/2 lg:w-1/3`,
    "md:w-2/3": tw`w-full md:w-2/3`,
    "md:w-3/4": tw`w-full md:w-3/4`,
    "lg:w-1/2": tw`w-full lg:w-1/2`,
    "lg:w-1/3": tw`w-full lg:w-1/3`,
    "lg:w-1/4": tw`w-full lg:w-1/4`,
    "lg:w-2/3": tw`w-full lg:w-2/3`,
    "lg:w-3/4": tw`w-full lg:w-3/4`,
    "xl:w-1/2": tw`w-full xl:w-1/2`,
    "w-1/3 md:w-full lg:w-1/3": tw`w-1/3 md:w-full lg:w-1/3`,
    "w-2/3 md:w-full lg:w-2/3": tw`w-full w-2/3 md:w-full lg:w-2/3`,
    "w-full": tw`w-full`,
  },
}

export const StyledColumn = styled.div<{
  width: string
  position: string
}>`
  ${styles.default}
  ${({ position }) => (position ? styles.position[position] : null)}
  ${({ width }) => styles.widths[width] || null}
`
