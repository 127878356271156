import React, { useState, useEffect } from "react"

import { useApp } from "../hooks/useApp"
import { useFirebase } from "../hooks/useFirebase"
import { useSanityMentor } from "../hooks/useSanity"

export const SavedItemsContext: any = React.createContext({})

export const SavedItemsProvider = ({ children }) => {
  const { customer } = useApp()
  const { firebase } = useFirebase()
  const { fetchMentorData } = useSanityMentor()

  const db = typeof window !== "undefined" ? firebase?.firestore() : null

  const [savedItems, setSavedItems] = useState<any[]>([])
  const [isWishlistPublic, setIsWishlistPublic] = useState(false)
  const [isInitialised, setIsInitialised] = useState(false)
  const [deleteItem, setDeleteItem] = useState(null)

  useEffect(() => {
    if (db && !isInitialised && customer?.email) {
      const getSavedItems = async () => {
        try {
          const snapshot = await db.collection(customer.email).get()
          const dbSavedItems: any[] = []
          snapshot.forEach(doc => {
            dbSavedItems.push(doc.data())
          })

          /**
           * Filter out mentors that are no longer active.
           */
          const mentors = await fetchMentorData({ ids: dbSavedItems.map(item => item.id) })
          setSavedItems(dbSavedItems.filter((item: any) => mentors.find(mentor => mentor._id === item.id)?.membership?.status !== false))
          setIsInitialised(true)
        } catch (error) {
          console.error(error)
          setIsInitialised(true)
        }
      }

      getSavedItems()
    }
  }, [db, isInitialised, customer?.email])

  useEffect(() => {
    if (db && customer?.email && isInitialised && savedItems?.length) {
      savedItems.forEach(item => {
        if (item.id) {
          db.collection(customer.email)
            .doc(item.id)
            .set(item)
        }
      })
    }
  }, [db, customer?.email, savedItems?.length, isInitialised])

  useEffect(() => {
    if (db && customer?.email && isInitialised && deleteItem) {
      db.collection(customer.email).doc(deleteItem).delete()
    }
  }, [deleteItem, customer?.email])

  const values = {
    savedItems,
    setSavedItems,
    isWishlistPublic,
    setIsWishlistPublic,
    setIsWishlistInitialized: setIsInitialised,
    setDeleteItem
  }

  return <SavedItemsContext.Provider value={{ ...values }}>{children}</SavedItemsContext.Provider>
}
