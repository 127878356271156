import tw, { css, styled } from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactPlayer from "react-player"

const styles = {
  ratio: {
    square: tw`pb-aspect-square`,
    "16/9": tw`pb-aspect-16/9`,
    "4/3": tw`pb-aspect-4/3`,
    "21/9": tw`pb-aspect-21/9`,
  },
}

export const StyledVideoSection = styled.div<{ ratio: string }>`
  ${tw`relative block w-full overflow-hidden rounded-lg z-0`}
  ${({ ratio }) => (ratio ? styles.ratio[ratio] : null)}
`

export const StyledVideoPlayer = styled(ReactPlayer)`
  ${tw`absolute z-1 inset-0 w-full h-full mx-auto`}
  ${css`
    .react-player__shadow {
      display: none !important;
    }
  `}
`
export const StyledVideoOverlay = styled.div<{ active: boolean }>`
  ${tw`absolute z-negitive inset-0 w-full h-full mx-auto bg-overlay transition-opacity duration-200`}
  ${({ active }) => (active ? tw`opacity-100` : tw`opacity-0`)}
`
export const StyledVideoImage = styled(GatsbyImage)`
  ${tw`z-negitive inset-0 w-full h-full mx-auto`}
  ${css`
    position: absolute !important;
    z-index: -1 !important;
  `}
`

export const StyledVideoControl = tw.button`absolute z-2 inset-0 flex justify-center items-center w-full h-full mx-auto cursor-pointer`
