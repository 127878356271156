import React, { useMemo, useCallback } from "react"

import { useApp } from "../../hooks/useApp"
import { useKlaviyo } from "../../hooks/useKlaviyo"

export const withPopupVideo = Component =>
  React.memo(({ name = "PopupVideo" }) => {
    const { activeVideo, setActiveVideo } = useApp()
    const { trackEvent } = useKlaviyo()

    const handlePopup = useCallback(() => {
      setActiveVideo(false)
      trackEvent("Viewed Video", activeVideo)
    }, [setActiveVideo, trackEvent, activeVideo])

    Component.displayName = name
    return useMemo(() => <Component video={activeVideo} handlePopup={handlePopup} />, [activeVideo])
  })
