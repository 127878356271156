import tw, { styled, css } from "twin.macro"

import { StyledPopupTitle } from "../../styled/Popup"

export const Outer = styled.section`
  ${tw`fixed inset-0 w-full h-full items-center justify-center z-50`}
  ${({ active }) => (active ? tw`flex` : tw`hidden`)}
  ${({ full }) => full && tw`z-10`}
`
export const Overlay = tw.div`absolute inset-0 bg-grey-dark opacity-90 cursor-pointer`
export const Inner = styled.div`
  ${tw`relative bg-white rounded-xl w-200 max-w-full py-12 px-14 overflow-y-auto md:max-h-popup`}
  ${({ full }) =>
    full &&
    css`
      height: calc(100vh - 4.75rem);
      ${tw`w-screen mt-17 md:mt-36 rounded-none bg-grey-lightest px-6 md:px-14 md:bg-white md:rounded-xl md:mt-0 md:w-200 md:h-auto`}
    `}
`
export const InnerSmall = styled.div`
  ${tw`relative bg-white rounded-xl w-100 max-w-full overflow-y-auto md:max-h-popup`}
  ${({ full }) =>
    full &&
    css`
      height: calc(100vh - 4.75rem);
      ${tw`w-screen mt-17 md:mt-36 rounded-none bg-grey-lightest px-6 md:px-14 md:bg-white md:rounded-xl md:mt-0 md:w-200 md:h-auto`}
    `}
`
export const Content = tw.div`relative flex flex-col items-center justify-center p-6`
export const ContentSmall = tw.div`p-6`
export const TitleContainer = tw.div`relative flex flex-col items-center justify-center p-6 border-b border-grey-light`
export const TitleContainerSmall = tw.div`relative flex flex-col items-start justify-center p-6 border-b border-grey-light`

export const Title = tw(StyledPopupTitle)`md:text-xl md:mb-12 px-8`
export const Close = tw.button`absolute top-0 right-0 self-end lg:hidden mr-4 mt-1`
export const CloseSmall = tw.button`absolute top-6 right-6`
