import React, { useMemo } from "react"

import { useApp } from "../../hooks/useApp"
import { useNavigation } from "../../hooks/useNavigation"

export const withMenu = Component =>
  React.memo(({ name = "Menu" }: any) => {
    const { activeMenu, setActiveMenu, customer } = useApp()
    const {
      header: { items },
    } = useNavigation()

    Component.displayName = name
    return useMemo(() => (activeMenu ? <Component items={items} setActiveMenu={setActiveMenu} customer={customer} /> : null), [
      items,
      activeMenu,
      customer,
    ])
  })
