import tw, { styled } from "twin.macro"
import { Link as GatsbyLink } from "gatsby"

import { P } from "../../../styled/Text"

export const Items = styled.ul`
  ${tw`inline-block w-64 bg-white rounded-bl-lg shadow-md`}
  ${({ inline }) => inline && tw`md:flex md:items-center md:justify-around md:w-full md:py-6 md:shadow-none md:pl-20`}
`
export const Item = styled.li`
  ${tw`flex items-center`}
  ${({ inline }) => (inline ? tw`md:flex-1 md:border-l md:border-grey md:last:border-r` : tw`border-b border-grey-pale last:border-none`)}
`
export const Link = styled(GatsbyLink)`
  ${tw`flex w-full`}
  ${({ inline }) => (inline ? tw`md:justify-center md:font-medium md:py-1 md:px-4` : tw`py-5 px-6`)}
`
export const Button = styled.a`
  ${tw`flex w-full outline-none`}
  ${({ inline }) => (inline ? tw`md:justify-center md:font-medium md:py-1 md:px-4` : tw`py-5 px-6`)}
`
export const Title = styled(P)`
  ${tw`ml-4 mb-0 lg:text-base text-base leading-none`}
`
export const Content = tw.span`font-bold lg:text-base text-base text-pink`
