import { useStaticQuery, graphql } from "gatsby"

export const useData = () => {
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "she-mentors-logo-white.png" }) {
        childImageSharp {
          gatsbyImageData(width: 300, placeholder: BLURRED, formats: [AUTO])
        }
      }
      heroImage: file(relativePath: { eq: "demo/demo-hero-poster.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 2000, placeholder: BLURRED, formats: [AUTO])
        }
      }
      podcastImage: file(relativePath: { eq: "she-mentors-podcasts.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, formats: [AUTO])
        }
      }
      hourImage: file(relativePath: { eq: "demo/demo-hour-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, formats: [AUTO])
        }
      }
      companyPlaceholder: file(relativePath: { eq: "she-mentors-company-placeholder.jpg" }) {
          childImageSharp {
              gatsbyImageData(width: 580, formats: [AUTO])
          }
      }
      profilePhoto: file(relativePath: { eq: "demo/demo-profile-photo.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 580, placeholder: BLURRED, formats: [AUTO])
        }
      }
      profilePlaceholder: file(relativePath: { eq: "she-mentors-placeholder.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 580, formats: [AUTO])
        }
      }
      profileDefault: file(relativePath: { eq: "she-mentors-default-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 580, placeholder: BLURRED, formats: [AUTO])
        }
      }
      profileImage: file(relativePath: { eq: "demo/demo-profile-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 280, placeholder: BLURRED, formats: [AUTO])
        }
      }
      newsImage: file(relativePath: { eq: "demo/demo-news-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 570, placeholder: BLURRED, formats: [AUTO])
        }
      }
      eventImage: file(relativePath: { eq: "demo/demo-event-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 320, placeholder: BLURRED, formats: [AUTO])
        }
      }
      blogImage: file(relativePath: { eq: "demo/demo-blog-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 800, placeholder: BLURRED, formats: [AUTO])
        }
      }
      podcastsImage: file(relativePath: { eq: "demo/demo-podcast-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 320, placeholder: BLURRED, formats: [AUTO])
        }
      }
      listitemImage: file(relativePath: { eq: "demo/demo-listitem-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 120, placeholder: BLURRED, formats: [AUTO])
        }
      }
      searchImage: file(relativePath: { eq: "demo/demo-search-image.png" }) {
        childImageSharp {
          gatsbyImageData(width: 128, placeholder: BLURRED, formats: [AUTO])
        }
      }
      videoImage: file(relativePath: { eq: "demo/demo-video-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 420, placeholder: BLURRED, formats: [AUTO])
        }
      }
      teamImage: file(relativePath: { eq: "demo/demo-team-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 120, placeholder: BLURRED, formats: [AUTO])
        }
      }
      teamPhoto: file(relativePath: { eq: "demo/demo-team-photo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 400, placeholder: BLURRED, formats: [AUTO])
        }
      }
      quoteImage: file(relativePath: { eq: "demo/demo-quote-image.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 120, placeholder: BLURRED, formats: [AUTO])
        }
      }
      profile2Image: file(relativePath: { eq: "demo/demo-profile2-photo.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 280, placeholder: BLURRED, formats: [AUTO])
        }
      }
    }
  `)

  return { data }
}
