import React, { useMemo } from "react"

import { useApp } from "../../hooks/useApp"
import { useSettings } from "../../hooks/useSettings"

export const withHeader = Component =>
  React.memo(({ name = "Header", title, basic, fixed }: any) => {
    const { customer, member, activeMenu, setActiveMenu, activeSearch, setActiveSearch } = useApp()
    const { membership } = useSettings()

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          title={title}
          customer={customer}
          member={member}
          fixed={fixed}
          basic={basic}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
          activeSearch={activeSearch}
          setActiveSearch={setActiveSearch}
          membershipActive={membership?.enabled}
        />
      ),
      [customer, member, activeMenu, activeSearch, fixed, basic]
    )
  })
