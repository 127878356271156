import React, { useMemo, useCallback } from "react"

import { useApp } from "../../hooks/useApp"

export const withPopupCompleteProfile = Component =>
  //@ts-ignore
  React.memo(({ name = "PopupCompleteProfile" }) => {
    const { customer, activeCompleteProfile, setActiveCompleteProfile } = useApp()

    const handlePopup = useCallback(show => setActiveCompleteProfile(show), [setActiveCompleteProfile])

    Component.displayName = name
    return useMemo(() => <Component title={""} content={""} activePopup={activeCompleteProfile} handlePopup={handlePopup} />, [
      customer,
      activeCompleteProfile,
    ])
  })
