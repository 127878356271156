import React from "react"
import app from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
// import "firebase/functions"

export const FirebaseContext = React.createContext<{ firebase: app.app.App | undefined }>({ firebase: undefined })

export const FirebaseProvider = ({ children, config }): JSX.Element => {
  if (typeof window !== "undefined") {
    const firebase = app.apps.length
      ? app.apps[0]
      : app.initializeApp({
          apiKey: config.apiKey,
          authDomain: config.authDomain,
          databaseURL: config.databaseURL,
          projectId: config.projectId,
        })

    if (process.env.NODE_ENV === "development")
      firebase.firestore().settings({
        host: "localhost:8080",
        ssl: false,
      })

    return <FirebaseContext.Provider value={{ firebase }}>{children}</FirebaseContext.Provider>
  } else {
    return <React.Fragment>{children}</React.Fragment>
  }
}

export const withFirebase = Component => (props): JSX.Element => (
  <FirebaseContext.Consumer>{({ firebase }) => <Component {...props} firebase={firebase} />}</FirebaseContext.Consumer>
)
