import React from "react"

import { withPopup } from "./withPopup"
import { Outer, Overlay, Inner, Content } from "./Popup.styled"

export const Popup = withPopup(({ active, full, setActive, children }: Props) => (
  <Outer active={active} full={full}>
    <Overlay onClick={() => setActive(false)} />
    <Inner full={full}>
      <Content>{children}</Content>
    </Inner>
  </Outer>
))

export interface Props {
  active?: boolean
  full?: boolean
  name?: string
  setActive: (boolean) => void
  children?: any
}
