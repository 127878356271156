import React from "react"

import { withIcon } from "./withIcon"
import { NormalIcon, DiscIcon } from "./Icon.styled"

import IconCross from "../../../static/icons/cross.svg"
import IconHamburger from "../../../static/icons/hamburger.svg"
import IconCheck from "../../../static/icons/check.svg"
import IconPlus from "../../../static/icons/plus.svg"
import IconMinus from "../../../static/icons/minus.svg"
import IconEye from "../../../static/icons/eye.svg"
import IconWrite from "../../../static/icons/write.svg"
import IconEdit from "../../../static/icons/edit.svg"
import IconSearch from "../../../static/icons/search.svg"
import IconPin from "../../../static/icons/pin.svg"
import IconWaiting from "../../../static/icons/waiting.svg"
import IconSettings from "../../../static/icons/settings.svg"
import IconInstagram from "../../../static/icons/instagram.svg"
import IconEmail from "../../../static/icons/email.svg"
import IconShareedin from "../../../static/icons/linkedin.svg"
import IconFacebook from "../../../static/icons/facebook.svg"
import IconShare from "../../../static/icons/share.svg"
import IconStar from "../../../static/icons/star.svg"
import IconNotification from "../../../static/icons/notification.svg"
import IconPlay from "../../../static/icons/play.svg"
import IconHeart from "../../../static/icons/heart.svg"
import IconBadge from "../../../static/icons/badge.svg"
import IconAward from "../../../static/icons/award.svg"
import IconHome from "../../../static/icons/home.svg"
import IconMembers from "../../../static/icons/members.svg"
import IconCart from "../../../static/icons/cart.svg"
import IconChecked from "../../../static/icons/checked.svg"
import IconUnchecked from "../../../static/icons/unchecked.svg"
import IconUser from "../../../static/icons/user.svg"
import IconCalendar from "../../../static/icons/calendar.svg"
import IconBell from "../../../static/icons/bell.svg"
import IconCrew from "../../../static/icons/crew.svg"
import IconArrowLeft from "../../../static/icons/arrow-left.svg"
import IconArrowRight from "../../../static/icons/arrow-right.svg"
import IconArrowUp from "../../../static/icons/arrow-up.svg"
import IconArrowDown from "../../../static/icons/arrow-down.svg"
import IconPointerLeft from "../../../static/icons/point-left.svg"
import IconPointerRight from "../../../static/icons/point-right.svg"
import IconPointerUp from "../../../static/icons/point-up.svg"
import IconPointerDown from "../../../static/icons/point-down.svg"
import IconChevronDown from "../../../static/icons/chevron-down.svg"
import IconChevronUp from "../../../static/icons/chevron-up.svg"
import IconChevronLeft from "../../../static/icons/chevron-left.svg"
import IconChevronRight from "../../../static/icons/chevron-right.svg"
import IconValid from "../../../static/icons/valid.svg"
import IconInvalid from "../../../static/icons/invalid.svg"
import IconGoogle from "../../../static/icons/google.svg"
import IconMicrosoft from "../../../static/icons/microsoft.svg"
import IconOutlook from "../../../static/icons/outlook.svg"
import IconYahoo from "../../../static/icons/yahoo.svg"
import IconHeartFill from "../../../static/icons/heart_fill.svg"
import Alert from "../../../static/icons/alert-triangle-color.svg"
import AlertWhite from "../../../static/icons/alert-triangle-white.svg"
import AlertRed from "../../../static/icons/alert-triangle-red.svg"
import Tick from "../../../static/icons/tick.svg"

const icons = {
  cross: IconCross,
  hamburger: IconHamburger,
  check: IconCheck,
  plus: IconPlus,
  minus: IconMinus,
  eye: IconEye,
  write: IconWrite,
  edit: IconEdit,
  search: IconSearch,
  pin: IconPin,
  waiting: IconWaiting,
  settings: IconSettings,
  instagram: IconInstagram,
  email: IconEmail,
  linkedin: IconShareedin,
  facebook: IconFacebook,
  share: IconShare,
  star: IconStar,
  notification: IconNotification,
  play: IconPlay,
  heart: IconHeart,
  badge: IconBadge,
  award: IconAward,
  home: IconHome,
  members: IconMembers,
  cart: IconCart,
  checked: IconChecked,
  unchecked: IconUnchecked,
  user: IconUser,
  calendar: IconCalendar,
  bell: IconBell,
  crew: IconCrew,
  arrowLeft: IconArrowLeft,
  arrowRight: IconArrowRight,
  arrowDown: IconArrowDown,
  arrowUp: IconArrowUp,
  pointerLeft: IconPointerLeft,
  pointerRight: IconPointerRight,
  pointerDown: IconPointerDown,
  pointerUp: IconPointerUp,
  chevronLeft: IconChevronLeft,
  chevronRight: IconChevronRight,
  chevronDown: IconChevronDown,
  chevronUp: IconChevronUp,
  valid: IconValid,
  invalid: IconInvalid,
  google: IconGoogle,
  microsoft: IconMicrosoft,
  outlook: IconOutlook,
  yahoo: IconYahoo,
  heartFill: IconHeartFill,
  alert: Alert,
  alertWhite: AlertWhite,
  alertRed: AlertRed,
  tick: Tick,
}

export const Icon = withIcon(
  ({ name, size, background = null, colour, state = null, disc = null }: Props): JSX.Element =>
    disc ? (
      <DiscIcon size={size} colour={colour} background={background} state={state}>
        <svg viewBox={icons[name].viewBox} id={icons[name].id}>
          <use xlinkHref={icons[name].url} />
        </svg>
      </DiscIcon>
    ) : (
      <NormalIcon size={size} colour={colour} state={state}>
        <svg viewBox={icons[name].viewBox} id={icons[name].id}>
          <use xlinkHref={icons[name].url} />
        </svg>
      </NormalIcon>
    )
)

export interface Props {
  size: string
  name: string
  background?: string
  colour?: string
  state?: any
  disc?: boolean
}
