import React, { useMemo, ReactNode } from "react"

export const withMentorCardContent = Component =>
  React.memo(({ name = "MentorCardContent", handleEdit, page, location, title, edit, children, ...props }: Props) => {
    Component.displayName = name
    return useMemo(
      () => <Component {...props} title={title} page={page} location={location} edit={edit} handleEdit={handleEdit} children={children} />,
      [children]
    )
  })

export interface Props {
  page?: any
  name?: string
  children: ReactNode
  mentor?: any
  title?: string
  edit?: any
  handleEdit: () => void
  location?: any
}
