module.exports = {
  app: {
    title: "She Mentors",
    description: "Mentoring for Women in Business",
    url: "https://shementors.com.au",
    logo: "static/images/logo.svg",
    themeColor: "#48BFA8",
    themeBackground: "#48BFA8",
    themeDisplay: "minimal-ui",
    themeIcon: "static/images/icon.png",
  },
  stores: {
    "she-mentors": {
      siteLocation: "AU",
      siteCountries: "AU",
      shopName: "she-mentors",
      accessToken: "70e53e125d6cc134367e4ba7d220f455",
      adminAccessToken: process.env.GATSBY_SHOPIFY_ADMIN_API,
      shopifyPlus: false,
      sanityDataset: "production",
      froalaApiKey: "iTB2xC2D5A4E2A1A1wd1DBKSPF1WKTUCQOa1OURPJ1KDe2F-11D2C2D2H2C3A3C2D6B1F1==",
    },
  },
  services: {
    shopify: {
      apiVersion: "2020-07",
      defaultShopName: process.env.GATSBY_SHOPIFY_DEFAULT_SHOP,
    },
    stripe: {
      publishKey: process.env.GATSBY_STRIPE_PUBLISH_KEY,
    },
    fullstory: {
      org: "XJ9E0",
      debug: process.env.GATSBY_ACTIVE_ENV === "development" || process.env.NODE_ENV === "development",
    },
    sanity: {
      projectId: "e549g7p5",
      token:
        "skBpCIGvG6U1CAjZ9DCj5hELVl3GsmlQOe3KaqCfMKSmWdz92crc13dv5rTFY0NFrTRZ1VUrvOvYnawRIyeXs1xCzSkuyKdlUtx6l7lLgo7cOgEey4dtLG4MxzO8XJIVxXfidx8Vr3I5pBc2yCgZMfOXgFvo3y2A4C1RH72PGRLd4sRKzy8d",
      dataset: process.env.GATSBY_SANITY_DEFAULT_DATASET,
    },
    firebase: {
      region: "us-central1",
      apiKey: "AIzaSyAiPOUThbSRydfsJpvYlLO48cRq5Xec_UU",
      authDomain: "she-mentors.firebaseapp.com",
      databaseURL: "https://she-mentors.firebaseio.com",
      projectId: "she-mentors",
      storageBucket: "she-mentors.appspot.com",
      messagingSenderId: "410586970732",
      appId: "1:410586970732:web:8c5703473ac4ab725b3649",
      measurementId: "G-NVXQWPQ6YG",
    },
    functions: {
      endpoint: "https://australia-southeast1-she-mentors.cloudfunctions.net",
      // change to "http://localhost:5001/she-mentors/australia-southeast1" for local development
    },
    location: {
      serviceUrl: "https://ipapi.co/json/",
      countryFieldKey: "country",
      forceRegion: true,
    },
    front: {
      verificationSecret: "32c824f15e8baaf60ae0a3ad73d363c4",
      chatId: "b9bdb11dff94a25005663e2031d6df9c",
    },
  },
  settings: {
    keys: {
      shopify: "app:shopify",
      country: "app:country",
      location: "app:location",
      checkout: "app:checkout",
      discounts: "app:discounts",
      customer: "app:customer",
      wishlist: "app:wishlist",
      announcement: "app:announcement",
      maintenance: "app:maintenance",
      password: "app:password",
    },
    routes: {
      PAGE: "",
      FLEXIBLE: "",
      GENERIC: "",
      HOMEPAGE: "/",
      CART: "/cart",
      CONTACT: "/contact",
      SEARCH: "/search",
      PASSWORD: "/password",
      LOGIN: "/account/login",
      REGISTER: "/account/register",
      ONBOARDING: "/account/onboarding",
      FORGOT: "/account/forgot",
      RESET: "/account/reset",
      ACTIVATE: "/account/activate",
      DASHBOARD: "/account/dashboard",
      PROFILE: "/account/profile",
      ORDERS: "/account/orders",
      ADDRESSES: "/account/addresses",
      PREFERENCES: "/account/preferences",
      WISHLIST: "/account/wishlist",
      MEMBERSHIP: "/account/membership",
      MEMBERSHIP_SUCCESS: "/account/membership/success",
      MEMBERSHIP_CANCELLED: "/account/membership/cancelled",
      MEMBERSHIPS: "/memberships",
      PRODUCT: "/products",
      COLLECTION: "/collections",
      MENTOR: "/mentors",
      MENTOR_REQUEST: "/request",
      MENTOR_FEEDBACK: "/feedback",
      EVENT: "/events",
      EVENT_REGISTER: "/register",
      PODCAST: "/podcasts",
      ARTICLE: "/articles",
      VIDEO: "/videos",
    },
    defaultRedirects: [{ source: "/home", destination: "/", type: 301 }],
    clientPaths: ["/account/reset/*", "/account/activate/*", "/mentors/requested/*", "/account/requests/*"],
    sitemapExclusions: [
      "/offline-plugin-app-shell-fallback",
      "/dev-404-page",
      "/404",
      "/404.html",
      "/password",
      "/preview",
      "/search",
      "/mentors",
      "/mentors/*",
      "/account",
      "/account/*",
      "/videos/*",
      "/instagram-bio",
    ],
  },
}
