import React from "react"

import { List, Item, Link } from "./FooterLinks.styled"

export const FooterLinks = ({ items = [] }): JSX.Element => (
  <List>
    {items.map((item, index) => (
      <Item key={index}>
        {item.link.external ? (
          <Link as={`a`} href={item.link.url} target={`_blank`}>
            {item.title}
          </Link>
        ) : (
          <Link to={item.link.url}>{item.title}</Link>
        )}
      </Item>
    ))}
  </List>
)

export interface Props {
  items: Array<{
    title: string
    url: string
    external?: boolean
  }>
}
