import React from "react"
import { Helmet } from "react-helmet"

import { withMeta } from "./withMeta"

export const Meta = withMeta(
  ({ languages, metadata, metatags, schemas }): JSX.Element => (
    <Helmet htmlAttributes={{ lang: metadata?.lang }} title={metadata?.title} meta={metatags}>
      <link href={metadata?.siteURL} rel="home" />
      <link href={metadata?.canonical} rel="canonical" />
      {languages?.map(({ key, href, hrefLang, rel }, index) => (
        <link key={key?.toString()} href={href} hrefLang={hrefLang} rel={rel} />
      ))}
      {schemas.map((schema, index) => (
        <script type="application/ld+json" key={index}>
          {JSON.stringify(schema)}
        </script>
      ))}
    </Helmet>
  )
)
