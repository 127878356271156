import { useCallback, useContext } from "react"

import { SavedItemsContext } from "../providers/savedItems"
import { useKlaviyo } from "./useKlaviyo"

const allowed = ["type", "subtitle", "title", "image", "link", "id"]

const filterData = (allowed, data) =>
  Object.keys(data)
    ?.filter(key => allowed.includes(key))
    ?.reduce((obj, key) => {
      obj[key] = data[key] || ""
      return obj
    }, {}) || {}

const formatData = data => ({
  ...filterData(allowed, data),
})

export const useSavedItemsContext = () => {
  const savedItemsData: any = useContext(SavedItemsContext)
  return { ...savedItemsData }
}

export const useToggleSavedItemsProduct = () => {
  const { setSavedItems, savedItems, setDeleteItem } = useSavedItemsContext()
  const { trackEvent } = useKlaviyo()

  const addToSavedItems = useCallback(
    item => {
      const mappedData = formatData(item)
      const saveItem = {
        ...mappedData,
        timestamp: new Date().getTime(),
        image: item?.image.src,
      }
      trackEvent("Saved Item Added", saveItem)
      setSavedItems(prevState => (prevState?.length ? [...prevState, saveItem] : [saveItem]))
    },
    [setSavedItems, formatData]
  )

  const deleteFromSavedItems = useCallback(
    id => {
      const removeItem = savedItems?.filter(item => item?.id == id)
      if (removeItem) {
        trackEvent("Saved Item Removed", removeItem)
        setDeleteItem(id)
        setSavedItems(prevState => prevState.filter(item => item?.id !== id))
      }
    },
    [setDeleteItem, savedItems]
  )
  return { addToSavedItems, deleteFromSavedItems }
}

export const useExistsInSavedItems = () => {
  const { savedItems } = useSavedItemsContext()
  const existsInSavedItems = useCallback(id => savedItems?.some(item => item?.id === id), [savedItems])
  return { existsInSavedItems }
}

export const useSavedItemsCount = () => {
  const { savedItems } = useSavedItemsContext()
  const count = savedItems?.length > 0 ? savedItems?.length : 0
  return { count }
}

export const useSavedItems = () => {
  const { savedItems } = useSavedItemsContext()
  const savedMentors = savedItems?.filter(item => item?.type == "mentor") || []
  const savedEvents = savedItems?.filter(item => item?.type == "event") || []
  const savedVideos = savedItems?.filter(item => item?.type == "video") || []
  const savedPodcasts = savedItems?.filter(item => item?.type == "podcast") || []
  return { savedMentors, savedEvents, savedVideos, savedPodcasts }
}
