import tw, { styled } from "twin.macro"

const styles = {
  size: {
    small: tw`py-1 px-4 text-xxs`,
    medium: tw`py-2 px-8 text-xs`,
    large: tw`py-4 px-12`,
  },
  colour: {
    primary: {
      light: tw`text-pink bg-white border-white hover:text-pink hover:bg-white shadow hover:shadow-none`,
      dark: tw`text-white bg-pink border-pink hover:bg-transparent shadow hover:shadow-none hover:bg-pink-light hover:border-pink-light`,
      outline: tw`hover:text-white text-pink hover:bg-pink border-pink border-2`,
    },
    secondary: {
      light: tw``,
      dark: tw`text-white bg-green border-green hover:bg-transparent shadow hover:shadow-none hover:bg-green-light hover:border-green-light`,
      outline: tw`hover:text-white text-green hover:bg-green border-green`,
    },
    tertiary: {
      light: tw`text-green bg-white border-white hover:text-grey-dark hover:bg-white shadow hover:shadow-none`,
      dark: tw`text-grey-mid bg-white border-white hover:text-grey-dark hover:bg-white shadow hover:shadow-none`,
      outline: tw`hover:text-grey-mid text-white hover:bg-white border-white`,
    },
    minimal: {
      light: tw`text-white bg-transparent border-white shadow-none hover:text-grey-pale hover:bg-transparent hover:border-grey-pale`,
      dark: tw`text-grey-mid bg-transparent border-grey-mid shadow-none hover:text-grey-dark hover:bg-transparent hover:border-grey-dark`,
      outline: tw``,
    },
  },
  state: {
    icon: {
      small: tw`pl-6`,
      medium: tw`pl-12`,
      large: tw`pl-12`,
    },
    loading: tw`pointer-events-none text-center!`, //tw`bg-pink-light text-white border-pink-light`,
    disabled: {
      primary: {
        light: tw`text-pink-light bg-white border-white shadow-none pointer-events-none`,
        dark: tw`bg-pink-light text-white border-pink-light shadow-none pointer-events-none`,
        outline: tw`text-pink-light border-pink-light bg-white shadow-none pointer-events-none`,
      },
      secondary: {
        light: tw`shadow-none pointer-events-none`,
        dark: tw`bg-green-light text-white border-green-light shadow-none pointer-events-none`,
        outline: tw`text-grey-light border-grey-light bg-white shadow-none pointer-events-none`,
      },
      tertiary: {
        light: tw`text-green-light bg-white border-white shadow-none pointer-events-none`,
        dark: tw`text-grey-light bg-white border-white shadow-none pointer-events-none`,
        outline: tw`text-grey-light border-white shadow-none pointer-events-none`,
      },
      minimal: {
        light: tw`text-grey-light bg-transparent border-white shadow-none pointer-events-none`,
        dark: tw`text-grey-light bg-transparent border-grey-light shadow-none pointer-events-none`,
        outline: tw`shadow-none pointer-events-none`,
      },
    },
  },
}

export const StyledButton = styled.button<{
  colour: string
  theme: string
  size: string
  icon: boolean
  disabled: boolean
  loading: boolean
}>`
  ${tw`select-none uppercase rounded-full font-bold tracking-wide outline-none relative overflow-hidden border focus:outline-none`}
  ${({ size }) => (size ? styles.size[size] : null)}
  ${({ theme, colour }) => (theme?.length ? styles.colour[theme][colour] : null)}
  ${({ icon, size }) => (icon ? styles.state.icon[size] : null)}
  ${({ loading }) => (loading ? styles.state.loading : null)}
  ${({ theme = "primary", colour = "dark", disabled }) => (disabled ? styles.state.disabled[theme][colour] : null)}
`
