import tw, { css, styled } from "twin.macro"

import IconChevronDownLight from "../../static/icons/chevron-down-light.svg"
import IconChevronDownDark from "../../static/icons/chevron-down-dark.svg"
import IconChevronDownHover from "../../static/icons/chevron-down-hover.svg"
import IconValid from "../../static/icons/valid.svg"
import IconInvalid from "../../static/icons/invalid.svg"
import IconWrite from "../../static/icons/write.svg"

const styles = {
  type: {
    default: tw`relative shadow-sm hover:border-pink focus:border-pink hover:shadow-none rounded-lg border border-grey-pale py-4 pl-4 pr-12 w-full appearance-none outline-none active:outline-none focus:outline-none text-grey-mid`,
    input: {
      default: ``,
      inherit: css`
        ::-webkit-input-placeholder {
          ${tw`opacity-100`}
          color: inherit;
        }
      `,
    },
    select: {
      default: css`
        ${tw`cursor-pointer`}
        fill: black;
        color: black;
        background: no-repeat calc(100% - 1rem) center white;
        background-size: 0.85rem 0.85rem;
        background-image: url(${IconChevronDownDark.url});

        &:not(:disabled):hover {
          background-image: url(${IconChevronDownHover.url});
        }

        option {
          color: black;
        }
      `,
      simple: css`
        ${tw`cursor-pointer border-t border-white border-b-0 border-l-0 border-r-0 hover:border-white focus:border-white shadow-none rounded-none py-2 px-0`}
        fill: white;
        color: white;
        background: no-repeat right center transparent;
        background-size: 0.85rem 0.85rem;
        background-image: url(${IconChevronDownLight.url});

        option {
          color: black;
        }
      `,
    },
    textarea: tw``,
  },
  state: {
    disabled: tw`hover:border-grey-pale shadow-none bg-transparent`,
    required: css`
      &:required:valid {
        ${tw`border-valid hover:border-valid focus:border-valid`}
      }
      &:required:focus:valid {
        ${css`
          background: no-repeat calc(100% - 1rem) center white;
          background-size: 1.2rem 1.2rem;
          background-image: url(${IconValid.url});
        `}
      }
      &:required:focus:invalid {
        ${tw`border-invalid`}
        ${css`
          background: no-repeat calc(100% - 1rem) center white;
          background-size: 1.2rem 1.2rem;
          background-image: url(${IconInvalid.url});
        `}
      }
    `,
    error: css`
      ${tw`border-invalid!`}
      ${css`
        background: no-repeat calc(100% - 1rem) center white !important;
        background-size: 1.2rem 1.2rem !important;
        background-image: url(${IconInvalid.url}) !important;
      `}
    `,
    edit: css`
      background: no-repeat 100% center white;
      background-size: 0.8rem 0.8rem;
      background-image: url(${IconWrite.url});
    `,
  },
}

export const StyledInput = styled.input<{
  variant: string
  required: boolean
  disabled: boolean
  error: boolean
  edit: boolean
}>`
  ${styles.type.default}
  ${({ variant }) => (variant ? styles.type.input[variant] : styles.type.input.default)}
  ${({ required }) => (required ? styles.state.required : ``)}
  ${({ disabled }) => (disabled ? styles.state.disabled : ``)}
  ${({ error }) => (error ? styles.state.error : ``)}
  ${({ edit }) => (edit ? styles.state.edit : ``)}
`

export const StyledSelect = styled.select<{
  variant: string
  required: boolean
  disabled: boolean
}>`
  ${styles.type.default}
  ${({ variant }) => (variant ? styles.type.select[variant] : styles.type.select.default)}
  ${({ required }) => (required ? styles.state.required : ``)}
  ${({ disabled }) => (disabled ? styles.state.disabled : ``)}
`

export const StyledTextArea = styled.textarea<{
  required: boolean
  disabled: boolean
}>`
  ${styles.type.default}
  ${styles.type.textarea}
  ${({ required }) => (required ? styles.state.required : ``)}
  ${({ disabled }) => (disabled ? styles.state.disabled : ``)}
`
