import React from "react"
import { withPopupVideo } from "./withPopupVideo"
import { Popup } from "./Popup"
import { Title, Close } from "./Popup.styled"
import { StyledVideoSection, StyledVideoPlayer } from "../Video/VideoDetails.styled"

import { Icon } from "../Icon/Icon"

export const PopupVideo = withPopupVideo(
  ({ video, handlePopup }: Props): JSX.Element => (
    <Popup active={video} setActive={handlePopup} full>
      <Close type={`button`} onClick={() => handlePopup(false)}>
        <Icon name={"cross"} size={"s"} colour={"tertiary"} />
      </Close>
      <Title>{video?.title}</Title>
      <StyledVideoSection ratio={"16/9"}>
        <StyledVideoPlayer
          url={video?.videoUrl || video?.video?.asset?.url}
          playing={true}
          loop={false}
          muted={false}
          controls={true}
          volume={1}
          width={"100%"}
          height={"100%"}
        />
      </StyledVideoSection>
    </Popup>
  )
)

export interface Props {
  video?: any
  activeVideo?: boolean
  handlePopup?: (show: boolean) => void
}
