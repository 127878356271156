import React, { useMemo, useCallback } from "react"

import { useApp } from "../../hooks/useApp"

export const withPopupAlreadyBooked = Component =>
  //@ts-ignore
  React.memo(({ name = "PopupAlreadyBooked" }) => {
    const { customer, activeAlreadyBooked, setActiveAlreadyBooked } = useApp()

    const handlePopup = useCallback(show => setActiveAlreadyBooked(show), [setActiveAlreadyBooked])

    Component.displayName = name
    return useMemo(() => <Component title={""} content={""} activePopup={activeAlreadyBooked} handlePopup={handlePopup} />, [
      customer,
      activeAlreadyBooked,
    ])
  })
