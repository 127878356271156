import React, { useMemo } from "react"

import { useNavigation } from "../../hooks/useNavigation"

export const withFooter = Component =>
  React.memo(({ name = "Footer", ...props }: any) => {
    const {
      footer: { items },
    } = useNavigation()
    Component.displayName = name
    return useMemo(() => <Component {...props} items={items} />, [])
  })
