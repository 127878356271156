import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

const styles = {
  theme: {
    primary: tw`text-pink hover:text-pink-light`,
    secondary: tw`text-green hover:text-green-light`,
    tertiary: tw`text-grey-mid hover:text-grey-light`,
  },
}

export const StyledLink = styled(Link)<{
  theme: string
}>`
  ${tw`inline-block uppercase text-xs tracking-wide leading-relaxed font-bold cursor-pointer`}
  ${({ theme }) => (theme ? styles.theme[theme] : null)}
`
