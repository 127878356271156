import React from "react"

import { withMentorCardContent } from "./withMentorCardContent"
import { Icon } from "../Icon/Icon"
import { Card, EditLink, Content, Title, AddButton } from "./MentorCardContent.styles"

export const MentorCardContent = withMentorCardContent(
  ({ title, theme, children = null, edit = false, mobileSpacing, handleEdit = () => false, isEmpty }: Props): JSX.Element => (
    <Card mobileSpacing={mobileSpacing} theme={theme}>
      {edit ? (
        isEmpty ? (
          <AddButton onClick={handleEdit}>+ ADD</AddButton>
        ) : (
          <EditLink onClick={handleEdit}>
            <Icon name={"edit"} size={"m"} />
          </EditLink>
        )
      ) : (
        <Icon name={"edit"} size={"m"} colour={"white"} />
      )}

      <Content theme={theme}>
        {title ? (
          <Title>
            {title} {edit && !isEmpty && <Icon name="tick" size="ml" />}
          </Title>
        ) : null}
        {children}
      </Content>
    </Card>
  )
)

export interface Props {
  title?: string
  children?: any
  edit?: boolean
  mobileSpacing?: boolean
  theme?: string
  handleEdit: () => void
  isEmpty?: boolean
}
