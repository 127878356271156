import React, { useState, useEffect } from "react"

export const SearchContext: any = React.createContext({})

export const SearchProvider = ({ children }): JSX.Element => {
  const defaultSearch = typeof window !== "undefined" ? window?.location?.search?.replace("?q=", "")?.replace(/%20/g, " ")?.toLowerCase() || "" : ""

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [threshold, setThreshold] = useState(0.3)
  const [query, setQuery] = useState(defaultSearch)
  const [results, setResults] = useState([])

  const [searched, setSearched] = useState(false)
  const [size, setSize] = useState(8)
  const [limit, setLimit] = useState(8)

  useEffect(() => {
    setLoading(false)
  }, [results])

  const values = {
    data,
    setData,
    searched,
    setSearched,
    loading,
    setLoading,
    limit,
    setLimit,
    size,
    setSize,
    query,
    setQuery,
    results,
    setResults,
    threshold,
    setThreshold,
  }

  return <SearchContext.Provider value={{ ...values }}>{children}</SearchContext.Provider>
}

export const withApp = Component => (props): JSX.Element => (
  <SearchContext.Consumer>{values => <Component {...props} layout={values} />}</SearchContext.Consumer>
)
