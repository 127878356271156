import React, { createContext, useContext, useState } from "react"

export const SanityContext = createContext({
  loadingRecommended: null,
  setLoadingRecommended: null,
  recommendedMentors: null,
  setRecommendedMentors: null,
  loadingSimilar: null,
  setLoadingSimilar: null,
  similarMentors: null,
  setSimilarMentors: null,
  loadingLiveEvents: null,
  setLoadingLiveEvents: null,
  liveEvents: null,
  setLiveEvents: null,
  loadingSimilarEvents: null,
  setLoadingSimilarEvents: null,
  similarEvents: null,
  setSimilarEvents: null,
  loadingNewArrival: null,
  setLoadingNewArrival: null,
  newArrivalMentors: null,
  setNewArrivalMentors: null,
})

export const SanityProvider = ({ children }) => {
  const [loadingRecommended, setLoadingRecommended] = useState(false)
  const [recommendedMentors, setRecommendedMentors] = useState([])
  const [loadingSimilar, setLoadingSimilar] = useState(false)
  const [similarMentors, setSimilarMentors] = useState([])
  const [loadingLiveEvents, setLoadingLiveEvents] = useState(false)
  const [liveEvents, setLiveEvents] = useState([])
  const [loadingSimilarEvents, setLoadingSimilarEvents] = useState(false)
  const [similarEvents, setSimilarEvents] = useState([])
  const [loadingNewArrival, setLoadingNewArrival] = useState(false)
  const [newArrivalMentors, setNewArrivalMentors] = useState([])
  const values = {
    loadingRecommended,
    setLoadingRecommended,
    recommendedMentors,
    setRecommendedMentors,
    loadingSimilar,
    setLoadingSimilar,
    similarMentors,
    setSimilarMentors,
    loadingLiveEvents,
    setLoadingLiveEvents,
    liveEvents,
    setLiveEvents,
    loadingSimilarEvents,
    setLoadingSimilarEvents,
    similarEvents,
    setSimilarEvents,
    newArrivalMentors,
    setNewArrivalMentors,
    loadingNewArrival,
    setLoadingNewArrival,
  }

  return <SanityContext.Provider value={{ ...values }}>{children}</SanityContext.Provider>
}

export const useSanityContext = () => useContext(SanityContext)
