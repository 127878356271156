import tw, { styled } from "twin.macro"

export const List = styled.ul`
  ${tw`flex items-start justify-center lg:justify-start lg:w-full lg:m-0`}
  ${({ centered }) => (centered ? tw`justify-center` : tw`justify-start lg:mt-8`)}
  ${({ disc }) => !disc && tw`lg:-m-4 lg:w-auto lg:self-start`}
  ${({ wrap }) => wrap && tw`flex-wrap lg:-m-2 lg:w-auto`}
  ${({ menu }) => (menu ? tw`ml-3` : tw`-ml-3`)}
`
export const Item = tw.li`text-center`
export const Link = styled.a`
  ${tw`block p-3 lg:py-0 lg:px-0 hover:text-grey-mid`}
  ${({ disc }) => !disc && tw`lg:m-4`}
  ${({ wrap }) => wrap && tw`lg:m-2`}
`
