import { useCallback, useEffect, useState } from "react"
import { useFunctions } from "./useFunctions"
import { useCustomerAccessToken, useCustomerDetails } from "./useCustomer"
import { useApp } from "./useApp"

export const useMembershipRefresh = ({ location }) => {
  const {
    member,
    config: {
      settings: {
        routes: { MEMBERSHIP_SUCCESS },
      },
    },
  } = useApp()

  const { customer } = useCustomerDetails()
  const { getCustomer } = useCustomerAccessToken()
  const { membershipGetSubscription } = useFunctions()

  const [awaitingUpdate, setAwaitingUpdate] = useState(false)

  const refreshMemberStatus = useCallback(async () => {
    const subscription = await membershipGetSubscription({ customerEmail: customer.email })

    // if the member has a paid account send the get customer
    // request again to update the status on the front end
    if (subscription?.status === "success") {
      getCustomer()
    }
  }, [setAwaitingUpdate, customer?.email])

  useEffect(() => {
    if (member?.membership?.vip) {
      setAwaitingUpdate(false)
    } else if (
      customer?.email &&
      member?.membership?.free &&
      (location?.state?.shouldRefreshMembership || location?.pathname?.includes(MEMBERSHIP_SUCCESS))
    ) {
      setAwaitingUpdate(true)
      refreshMemberStatus()
    }
  }, [location?.state?.shouldRefreshMembership, location, customer?.email, member?.membership?.free, member?.membership?.vip])

  return { awaitingUpdate, setAwaitingUpdate, refreshMemberStatus }
}
