import React from "react"

import { withPopupAlreadyBooked } from "./withPopupAlreadyBooked"
import { Popup } from "./Popup"
import { Close } from "./Popup.styled"
import { StyledPopupTitle, StyledPopupP, StyledPopupButton } from "../../styled/Popup"
import { Icon } from "../Icon/Icon"

export const PopupAlreadyBooked = withPopupAlreadyBooked(
  ({ activePopup, handlePopup }: Props): JSX.Element => (
    <Popup active={activePopup} setActive={handlePopup} full>
      <Close type={`button`} onClick={() => handlePopup(false)}>
        <Icon name={"cross"} size={"s"} colour={"tertiary"} />
      </Close>
      <StyledPopupTitle>You have already booked this month</StyledPopupTitle>
      <StyledPopupP>All mentor hours reset on the 1st of the month.</StyledPopupP>
      <br />
      <StyledPopupButton colour={`dark`} size={`large`} theme={`primary`} as={"a"} onClick={() => handlePopup(false)}>{`Close`}</StyledPopupButton>
    </Popup>
  )
)

export interface Props {
  title?: String
  content?: any
  activePopup?: boolean
  handlePopup?: (show: boolean) => void
}
