import React from "react"
import tw from "twin.macro"
import ReactLoading from "react-loading"

import { StyledButton } from "../../styled/Button"

const Spinner = tw.div`inline-block -mb-2`

export const Button = ({ loading, children, ...props }): JSX.Element => (
  <StyledButton theme={props.themeOverride || "primary"} {...props} loading={loading}>
    {loading ? (
      <Spinner>
        <ReactLoading type={"spin"} color={"#fff"} height={25} width={25} />
      </Spinner>
    ) : (
      children
    )}
  </StyledButton>
)
