import React, { useEffect } from "react"

import { useApp } from "../../hooks/useApp"
import { useSettings } from "../../hooks/useSettings"
import { useMaintenance } from "../../hooks/useMaintenance"
import { useSanityMentor, useSanityEvent } from "../../hooks/useSanity"
import { useSearchContent } from "../../hooks/useSearch"
import { useAnalytics } from "../../hooks/useAnalytics"
import { useCustomerAccessToken } from "../../hooks/useCustomer"
import { useStripe } from "../../hooks/useStripe"
import { useFront } from "../../hooks/useFront"
import { useMembershipRefresh } from "../../hooks/useMembershipRefresh"

export const withLayout =
  Component =>
  ({ name = "Layout", location, children, data, settings: { settings } }: any) => {
    const {
      preferences: { title },
    } = useSettings()
    const {
      ready,
      customer,
      activeMenu,
      setActiveMenu,
      setActiveSearch,
      activePopup,
      setActivePopup,
      setActiveVideo,
      setActiveSubscribe,
      setActiveCompleteProfile,
      setActiveAlreadyBooked,
      fixed: bodyFixed,
      setFixed,
      config: {
        services: { fullstory },
      },
    } = useApp()
    const { similarMentors, setSimilarMentors } = useSanityMentor()
    const { similarEvents, setSimilarEvents, liveEvents, setLiveEvents } = useSanityEvent()
    const { active, enabled } = useMaintenance(location)
    const { results, setResults } = useSearchContent("")
    const { getCustomer } = useCustomerAccessToken()
    const { trackEvent } = useAnalytics()
    const { loading } = useStripe()
    const { updateChat } = useFront()
    const { awaitingUpdate, setAwaitingUpdate, refreshMemberStatus } = useMembershipRefresh({ location })

    const path = location.pathname || ""
    const homepage = path === "/" || path === "" || path === undefined || path === null
    const routes = settings?.routes
    const basic = homepage
    const fixed = homepage

    const fill =
      path.includes("/account/login") ||
      path.includes("/account/forgot") ||
      path.includes("/account/reset") ||
      path.includes("/account/activate") ||
      path.includes("/account/register") ||
      path.includes("/account/onboarding") ||
      path.includes("/account/membership/success") ||
      path.includes("/account/membership/cancelled") ||
      path.includes("/corporate/success") ||
      path.includes("/corporate/cancelled") ||
      path.includes("/404") ||
      path.includes("/contact") ||
      path.includes("/password") ||
      path.includes("/register") ||
      path.includes("/404") ||
      path === undefined

    const center =
      path.includes("/account/login") ||
      path.includes("/account/forgot") ||
      path.includes("/account/reset") ||
      path.includes("/account/activate") ||
      path.includes("/account/register") ||
      path.includes("/account/onboarding") ||
      path.includes("/account/membership/success") ||
      path.includes("/account/membership/cancelled") ||
      path.includes("/corporate/success") ||
      path.includes("/corporate/cancelled") ||
      path.includes("/404") ||
      path.includes("/register") ||
      path.includes("/404") ||
      path === undefined

    const hideFooter = path.includes("/account/onboarding")

    const loadingApp = !ready || loading

    useEffect(() => {
      setFixed(false)
      setActiveMenu(false)
      setActiveSearch(false)
      setActivePopup(false)
      setActiveVideo(false)
      setActiveSubscribe(false)
      setActiveCompleteProfile(false)
      setActiveAlreadyBooked(false)

      if (liveEvents?.length) setLiveEvents([])
      if (similarEvents?.length) setSimilarEvents([])
      if (similarMentors?.length) setSimilarMentors([])
      if (results?.length) setResults([])

      trackEvent()
    }, [path])

    useEffect(() => {
      document.body.className = bodyFixed ? "fixed md:relative w-full max-w-full" : ""
      document.body.className = activeMenu || activePopup ? "fixed w-full md:relative" : ""
      document.body.className = loadingApp ? "fixed w-full" : ""
    }, [bodyFixed, activeMenu, activePopup, loadingApp])

    useEffect(() => {
      if (!customer?.id) getCustomer()
    }, [])

    useEffect(() => {
      updateChat(customer)
    }, [customer?.email])

    Component.displayName = name
    return active ? (
      <>{children}</>
    ) : (
      !enabled && (
        <Component
          data={data}
          path={path}
          title={title}
          routes={routes}
          homepage={homepage}
          children={children}
          fill={fill}
          center={center}
          basic={basic}
          fixed={fixed}
          hideFooter={hideFooter}
          loading={loadingApp}
          fullstory={fullstory}
          awaitingUpdate={awaitingUpdate}
          setAwaitingUpdate={setAwaitingUpdate}
          refreshMemberStatus={refreshMemberStatus}
        />
      )
    )
  }
