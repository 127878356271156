import React from "react"
import { StyledPopupP, StyledPopupTitle } from "../../../styled/Popup"
import { Popup } from "../../Popup/Popup"
import { Button } from "./Membership.styled"

type Props = {
  active: boolean
  refreshMemberStatus: () => Promise<void>
}

export const PopupAwaitingMembership: React.FC<Props> = ({ active, refreshMemberStatus }) => {
  return (
    <Popup active={active} setActive={() => null}>
      <StyledPopupTitle>Membership Upgrading...</StyledPopupTitle>
      <StyledPopupP>Please wait a moment while we update your account.</StyledPopupP>
      <Button colour={`dark`} size={`large`} theme={`primary`} onClick={refreshMemberStatus}>
        {"Complete"}
      </Button>
    </Popup>
  )
}
