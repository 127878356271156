import tw, { styled } from "twin.macro"

const styles = {
  screens: {
    "<sm": tw`block sm:hidden`,
    "sm>": tw`hidden sm:block`,
    "<md": tw`block md:hidden`,
    "md>": tw`hidden md:block`,
    "<lg": tw`block lg:hidden`,
    "lg>": tw`hidden lg:block`,
    "<xl": tw`block xl:hidden`,
    "xl>": tw`hidden xl:block`,
    "all": tw`block`,
  },
}

export const StyledResponsive = styled.div<{
  screen: string
}>`
  ${({ screen }) => styles.screens[screen] || null}
`
