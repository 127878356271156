import tw, { styled } from "twin.macro"

export { StyledResponsive as Responsive } from "../../styled/Responsive"
export { StyledContainer as Container } from "../../styled/Container"

export const Wrapper = tw.footer`flex-shrink-0`
export const Section = styled.section`
  ${tw`bg-grey-dark text-white`}
  ${({ dark }) => !dark && tw`lg:bg-grey-mid`}
  ${({ spacing }) => spacing && tw`pb-16 md:pb-0`}
`
export const Row = tw.div`-mx-8 lg:mx-0 lg:flex lg:flex-wrap lg:items-stretch lg:justify-between lg:py-10`
export const Column = tw.div`w-full lg:w-2/3 lg:flex lg:flex-col lg:items-start lg:justify-between`
