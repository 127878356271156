import React from "react"
import { Link } from "gatsby"

import { withHeader } from "./withHeader"

import { Icon } from "../Icon/Icon"
import { Logo } from "../Logo/Logo"
import { Widget } from "../Acccount/Widget/Widget"
// import { Search } from "../Search/Search"
import { Menu } from "../Menu/Menu"

import { StyledHeader } from "../../styled/Header"
import { StyledColumn } from "../../styled/Column"
import { StyledRow } from "../../styled/Row"
import { StyledResponsive } from "../../styled/Responsive"

import { Row, StyledLink, LogoLink, Button } from "./Header.styled"
import { UpgradeButton } from "../UpgradeButton/UpgradeButton"

export const Header = withHeader(
  ({
    children,
    activeMenu,
    setActiveMenu,
    // activeSearch,
    // setActiveSearch,
    fixed = false,
    basic = false,
    join = true,
    notification = 0,
    member,
    customer,
    membershipActive,
  }): JSX.Element => (
    <>
      <Menu />

      <StyledHeader fixed={fixed} basic={basic}>
        <StyledResponsive screen={`<lg`}>
          <Row stretch>
            <StyledColumn position={"left"} width={`md:w-1/3`}>
              {activeMenu ? (
                <StyledLink pink onClick={() => setActiveMenu(false)}>
                  <Icon name={"cross"} size={"m"} colour={"white"} />
                </StyledLink>
              ) : (
                <StyledLink onClick={() => setActiveMenu(true)}>
                  <Icon name={"hamburger"} size={"m"} colour={basic ? "white" : "tertiary"} />
                </StyledLink>
              )}
              {/* {!basic && (
                <StyledLink onClick={() => setActiveSearch(true)}>
                  <Icon name={"search"} size={"m"} colour={"tertiary"} />
                </StyledLink>
              )} */}
            </StyledColumn>

            {!basic && (
              <>
                <StyledColumn position={"center"} width={`md:w-1/3`}>
                  <LogoLink to={customer ? "/account/dashboard" : "/"}>
                    <Logo />
                  </LogoLink>
                </StyledColumn>
                <StyledColumn position={"right"} width={`md:w-1/3`}>
                  <Widget notification={notification} member={member} customer={customer} />
                </StyledColumn>
              </>
            )}

            {/* {activeSearch && <Search setActive={setActiveSearch} />} */}
          </Row>
        </StyledResponsive>

        <StyledResponsive screen={`lg>`}>
          <Row stretch>
            {children ? (
              <>
                <StyledColumn position={"left"}>
                  <LogoLink to={customer ? "/account/dashboard" : "/"}>
                    <Logo />
                  </LogoLink>
                </StyledColumn>
                <StyledColumn position={"center"}>{children}</StyledColumn>
                <StyledColumn position={"right"}>
                  <StyledRow>
                    {/* <StyledColumn position={"right"}>
                      <StyledLink onClick={() => setActiveSearch(true)}>
                        <Icon name={"search"} size={"m"} colour={"tertiary"} />
                      </StyledLink>
                    </StyledColumn> */}
                    <StyledColumn position={"right"}>
                      <Widget notification={notification} member={member} customer={customer} />
                    </StyledColumn>
                  </StyledRow>
                </StyledColumn>
              </>
            ) : (
              <>
                <StyledColumn position={"left"} width={`md:w-1/3`}>
                  {activeMenu ? (
                    <StyledLink pink onClick={() => setActiveMenu(false)}>
                      <Icon name={"cross"} size={"m"} colour={"white"} />
                    </StyledLink>
                  ) : (
                    <StyledLink onClick={() => setActiveMenu(true)}>
                      <Icon name={"hamburger"} size={"m"} colour={"tertiary"} />
                    </StyledLink>
                  )}

                  {/* {activeSearch ? (
                    <Search setActive={setActiveSearch} />
                  ) : (
                    <StyledLink onClick={() => setActiveSearch(true)}>
                      <Icon name={"search"} size={"m"} colour={"tertiary"} />
                    </StyledLink>
                  )} */}
                </StyledColumn>

                <StyledColumn position={"center"} width={`md:w-1/3`}>
                  <LogoLink to={customer ? "/account/dashboard" : "/"}>
                    <Logo />
                  </LogoLink>
                </StyledColumn>

                <StyledColumn position={"right"} width={`md:w-1/3`}>
                  {join && !customer?.id && (
                    <Button as={Link} to={`/account/login`} size={`large`} theme={`primary`} colour={`dark`}>
                      {!membershipActive ? "Login" : "Sign Up / Login"}
                    </Button>
                  )}
                  {customer?.id && member?.membership?.free ? <UpgradeButton /> : null}
                  <Widget notification={notification} member={member} customer={customer} />
                </StyledColumn>
              </>
            )}
          </Row>
        </StyledResponsive>
      </StyledHeader>
    </>
  )
)
