import { GatsbyImage } from "gatsby-plugin-image"
import tw from "twin.macro"

import { H5 } from "../../../styled/Text"

export { StyledDiscIcon as DiscIcon } from "../../../styled/Icon"

export const ProfileLink = tw.button`relative w-12 mr-3 md:mr-6 hidden md:block`
export const ProfileMobile = tw.button`relative w-12 mr-3 md:mr-6 md:hidden focus:outline-none active:outline-none select-none`
export const ProfileH5 = tw(H5)`mb-0 text-grey-light`
export const Bubble = tw.span`absolute top-0 right-0 rounded-full text-center w-4 h-4 bg-pink text-white text-xxxs font-bold leading-relaxed -mr-2`
export const Dropdown = tw.div`hidden group-hover:block absolute top-12 pt-3 right-0 z-2 -mr-10 md:-mr-6`
export const Image = tw(GatsbyImage)`rounded-full`
