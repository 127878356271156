import React from "react"

import { List, Item, Link } from "./MenuItems.styled"

export const MenuItems = ({ inline = false, items = [] }: Props): JSX.Element => (
  <List inline={inline}>
    {items?.length &&
      items?.map((item, index) => (
        <Item key={index}>
          {item?.link?.external ? (
            <Link as={`a`} href={item?.link?.url} target={`_blank`} inline={inline}>
              {item?.title}
            </Link>
          ) : (
            <Link active={item?.active} inline={inline} to={item?.link?.url}>
              {item?.title}
            </Link>
          )}
        </Item>
      ))}
  </List>
)

export interface Props {
  items: Array<{
    title: string
    url?: string
    link?: any
    active?: boolean
    external?: boolean
  }>
  inline?: boolean
}
