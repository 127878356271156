import React, { useState } from "react"
import TagManager from "react-gtm-module"
import VisibilitySensor from "react-visibility-sensor"

export const TrackingContext: any = React.createContext({})

export const TrackingProvider = ({ children }): JSX.Element => {
  const [tracked, setTracked] = useState(false)

  const values = {
    gtm: TagManager,
    tracked,
    setTracked,
    VisibilitySensor: VisibilitySensor,
  }

  return <TrackingContext.Provider value={{ ...values }}>{children}</TrackingContext.Provider>
}

export const withTracking = Component => (props): JSX.Element => (
  <TrackingContext.Consumer>{values => <Component {...props} layout={values} />}</TrackingContext.Consumer>
)
