import React from "react"

import { withMenu } from "./withMenu"
import { Icon } from "../Icon/Icon"
import { MenuItems } from "./MenuItems"
import { SocialLinks } from "../SocialLinks/SocialLinks"
import { Button, Close, Link, Nav, Overlay, Section, Side, UpgradeButtonWrapper } from "./Menu.styled"
import { UpgradeButton } from "../UpgradeButton/UpgradeButton"
import { useApp } from "../../hooks/useApp"

export const Menu = withMenu(
  ({ items = [], setActiveMenu, customer }: Props): JSX.Element => {
    const { member } = useApp()

    return (
      <>
        <Side>
          <Section>
            <Nav>
              {customer?.id && member?.membership?.free ? (
                <UpgradeButtonWrapper>
                  <UpgradeButton size="medium" />
                </UpgradeButtonWrapper>
              ) : (
                <Link to={customer?.id ? "/account/dashboard" : "/account/login"}>
                  <Button size={"medium"} theme={"primary"} colour={"dark"}>
                    {customer?.id ? "Dashboard" : "Sign Up / Login"}
                  </Button>
                </Link>
              )}
              <MenuItems items={items}/>
              <SocialLinks size={"m"} menu/>
            </Nav>
            <Close onClick={() => setActiveMenu(false)} type={"button"}>
              <Icon name={"cross"} size={"s"} colour={"white"}/>
            </Close>
          </Section>
        </Side>
        <Overlay onClick={() => setActiveMenu(false)}/>
      </>
    )
  }
)

export interface Props {
  items: Array<{
    title: string
    url: string
    external?: boolean
  }>
  customer?: any
  setActiveMenu?: (boolean) => void
}
