import tw, { styled, css } from "twin.macro"

export const Wrapper = styled.div`
  ${tw`min-h-screen flex flex-col bg-grey-lightest`}
  ${({ fixed }) => fixed && tw`fixed`}
  .gatsby-image-wrapper {
    z-index: 0;
  }
`

export const Content = styled.div`
  ${tw`flex flex-col`}
  ${({ homepage = false }) =>
    !homepage &&
    css`
      flex: 1 0 auto;
    `}
`

export const Main = styled.main<{
  center: boolean
  fill: boolean
}>`
  ${({ center }) => center && tw`md:flex md:flex-col md:items-center md:justify-center`}
  ${({ fill }) => fill && tw`flex-1 md:flex md:flex-col pb-12`}
`
