import { getGatsbyImageData } from "gatsby-source-sanity"

import { useApp } from "./useApp"
import { useData } from "./useData"
export const useImage = () => {
  const {
    config: {
      services: { sanity },
    },
  } = useApp()

  const {
    data: { profilePlaceholder },
  } = useData()

  const getGatsbyImage = (image, options = {}) => {
    if (!image) {
      return profilePlaceholder.childImageSharp.gatsbyImageData
    }
    if (image?.images) {
      return { ...image, ...options }
    }
    if (!image?.asset) {
      image.asset = image?.imageAsset
    }
    try {
      return image.asset && getGatsbyImageData(image, options, sanity)
    } catch (error) {
      console.error("error", error, image)
    }
  }

  return { getGatsbyImageData: getGatsbyImage }
}
