import React from "react"

import { withFooter } from "./withFooter"

import { FooterBackToTop } from "./FooterBackToTop"
import { FooterLinks } from "./FooterLinks"
import { FooterSubscribe } from "./FooterSubscribe"
import { FooterCopyright } from "./FooterCopyright"
import { SocialLinks } from "../SocialLinks/SocialLinks"
import { Container, Responsive, Wrapper, Section, Row, Column } from "./Footer.styled"

const footer = React.createRef()

export const Footer = withFooter(
  ({ spacing, items = [] }: Props): JSX.Element => (
    <Wrapper ref={footer}>
      <Section>
        <Container width={`xl`}>
          <Row>
            <Column>
              <FooterLinks items={items} />
              <Responsive screen={`lg>`}>
                <SocialLinks colour={"white"} size={"m"} />
              </Responsive>
            </Column>
            <FooterSubscribe title={"Subscribe to our newsletter"} />
          </Row>
        </Container>
      </Section>

      <Section dark spacing={spacing}>
        <Container width={`xl`}>
          <Responsive screen={`<lg`}>
            <SocialLinks mobile colour={"white"} size={"m"} centered />
          </Responsive>
          <FooterCopyright brand={"She Mentors"} termsLink={"/terms-and-conditions"} />
        </Container>
      </Section>

      <FooterBackToTop footer={footer} />
    </Wrapper>
  )
)

export interface Props {
  spacing?: boolean
  items?: Array<any>
}
