import React, { useState, useMemo } from "react"

import { useApp } from "../../hooks/useApp"
import { useFunctions } from "../../hooks/useFunctions"
import { useKlaviyo } from "../../hooks/useKlaviyo"

export const withFooterSubscribe = Component =>
  React.memo(({ name = "FooterSubscribe", title }: any) => {
    const { customer } = useApp()
    const { customerSubscribe, loading, errors } = useFunctions()
    const { trackEvent } = useKlaviyo()
    const [data, setData] = useState({ email: customer?.email || "" })

    const handleSubmit = async event => {
      event.preventDefault()
      const { customer } = await customerSubscribe(data?.email)
      if (customer?.id) {
        setData({ email: "" })
        trackEvent("Completed Form Subscribe", { email: data?.email })
      }
    }

    const handleChange = ({ target: { type, name, value, checked } }) => {
      setData(prevState => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }))
    }

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          title={title}
          data={data}
          subscribed={customer?.acceptsMarketing}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loading={loading}
          errors={errors}
        />
      ),
      [customer, data, loading, errors]
    )
  })
