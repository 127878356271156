import { useCallback } from "react"

import { useSettings } from "./useSettings"
import { useRoutes } from "./useRoutes"

export const useNavigation = () => {
  const {
    menus: { account, header, footer },
  } = useSettings()

  const { linkResolver } = useRoutes()

  const transformItems = item => {
    if (!item) return {}

    const { title, items } = item
    const link = linkResolver(item)
    const content = item[link.type] || null

    return {
      key: item._key,
      content,
      item,
      items: items?.length ? items.map(item => transformItems(item)) : [],
      link,
      title,
    }
  }

  const transformMenu = ({ handle = null, items = [], title = "" }) => ({
    handle: handle?.current,
    items: items?.length ? items?.map(item => transformItems(item)) : [],
    title,
  })

  return {
    header: transformMenu(header),
    footer: transformMenu(footer),
  }
}
