import React from "react"

import { Icon } from "../../Icon/Icon"
import { Items, Item, Link, Button, Title, Content } from "./WidgetItems.styled"

export const WidgetItems = ({ inline, links = [] }: Props): JSX.Element => (
  <Items inline={inline}>
    {links.map((item, index) => (
      <Item key={index} inline={inline}>
        {item.link.length ? (
          <Link to={item.link} inline={inline}>
            <Icon name={item.icon} size={"m"} colour={"primary"} />
            <Title>
              {item.title}
              {item.content ? <Content> ({item.content})</Content> : null}
            </Title>
          </Link>
        ) : (
          <Button
            onClick={e => {
              e.preventDefault()
              item.onClick()
            }}
            inline={inline}
          >
            <Icon name={item.icon} size={"m"} colour={"primary"} />
            <Title>
              {item.title}
              {item.content ? <Content> ({item.content})</Content> : null}
            </Title>
          </Button>
        )}
      </Item>
    ))}
  </Items>
)

export interface Props {
  inline?: boolean
  links: Array<{
    icon: string
    title: string
    content?: string
    link?: string
    onClick?: () => any
  }>
}
