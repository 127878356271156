import React from "react"
import { Link } from "gatsby"

import { withPopupCompleteProfile } from "./withPopupCompleteProfile"
import { Popup } from "./Popup"
import { Close } from "./Popup.styled"
import { StyledPopupTitle, StyledPopupP, StyledPopupButton } from "../../styled/Popup"
import { Icon } from "../Icon/Icon"

export const PopupCompleteProfile = withPopupCompleteProfile(
  ({ activePopup, handlePopup }: Props): JSX.Element => (
    <Popup active={activePopup} setActive={handlePopup} full>
      <Close type={`button`} onClick={() => handlePopup(false)}>
        <Icon name={"cross"} size={"s"} colour={"tertiary"} />
      </Close>
      <StyledPopupTitle>Complete your profile</StyledPopupTitle>
      <StyledPopupP>
        We ask all members to complete their profile before taking part in The Mentor Hour. This ensures that you are available to help others, should
        they need it.
      </StyledPopupP>

      <br />
      <StyledPopupButton colour={`dark`} size={`large`} theme={`primary`} as={Link} to={"/account/profile"}>{`Complete Profile`}</StyledPopupButton>
    </Popup>
  )
)

export interface Props {
  title?: String
  content?: any
  activePopup?: boolean
  handlePopup?: (show: boolean) => void
}
