import tw, { css, styled } from "twin.macro"

const styles = {
  size: {
    small: tw`p-3 w-card-s mb-4`,
    "less-medium": tw`p-5 w-card-m mb-4`,
    medium: tw`p-5 w-card-m mb-4`,
    large: tw`p-6 w-card-l mb-4`,
    xlarge: tw`p-8 mb-4`,
    square: tw`h-0 pb-aspect-square mb-4`,
    horizontal: tw`h-0 pb-aspect-16/9 mb-4`,
    vertical: tw`h-0 pb-aspect-vertical mb-4`,
    full: tw`p-6 w-full`,
  },
  shadow: {
    small: tw`shadow-sm`,
    medium: tw`shadow-md`,
    large: tw`shadow-lg`,
    full: tw`shadow-lg`,
  },
  background: {
    primary: tw`bg-pink`,
    secondary: tw`bg-green`,
    transparent: tw`bg-transparent`,
    "grey-pale": tw`bg-grey-pale`,
    "grey-lightest": tw`bg-grey-lightest`,
  },
  state: {
    hover: {
      small: tw`hover:shadow-md transition-shadow duration-150 cursor-pointer`,
      medium: tw`hover:shadow-lg transition-shadow duration-150 cursor-pointer`,
      large: tw`hover:shadow-xl transition-shadow duration-150 cursor-pointer`,
      full: tw`hover:shadow-xl transition-shadow duration-150 cursor-pointer`,
    },
    horizontal: tw`p-3`,
  },
}

export const StyledCard = styled.figure<{
  size?: string
  shadow?: string
  background?: string
  gradient?: boolean
  hover?: boolean
  horizontal?: boolean
  noMargin?: boolean
}>`
  ${tw`block relative rounded-xl bg-white`}
  ${({ size }) => (size ? styles.size[size] : null)}
  ${({ noMargin }) => noMargin && tw`mb-0`}
  ${({ background, gradient }) => (background && !gradient ? styles.background[background] : null)}
  ${({ background, gradient }) =>
    gradient
      ? background === "primary"
        ? css`
            background: linear-gradient(180deg, #fe6086 0%, #ff007d 100%);
          `
        : background === "secondary"
        ? css`
            background: linear-gradient(180deg, #61d7c4 0%, #48bfa8 100%);
          `
        : null
      : null}
  ${({ shadow }) => (shadow ? styles.shadow[shadow] : null)}
  ${({ hover, shadow }) => (hover ? styles.state.hover[shadow] : null)}
  ${({ horizontal }) => (horizontal ? styles.state.horizontal : null)}
`
