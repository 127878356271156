import React from "react"

import { Section, Paragraph, Link } from "./FooterCopyright.styled"

export const FooterCopyright = ({ brand, termsLink }: Props): JSX.Element => (
  <Section>
    <Paragraph>
      Copyright &copy; {new Date().getFullYear()} {brand}.
    </Paragraph>
    <Paragraph>
      All rights reserved. <Link to={termsLink}>Terms and conditions</Link> and <Link to={"/privacy-policy"}>privacy policy</Link>.
    </Paragraph>
  </Section>
)

export interface Props {
  brand: string
  termsLink: string
}
