import { useRef, useLayoutEffect } from "react"

export const isBrowser = typeof window !== `undefined`

export function useScrollTo({ x, y }) {
  if (!isBrowser) return null

  window.scrollTo({
    top: y,
    left: x,
    behavior: "smooth",
  })
}

function getScrollPosition({ element }) {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = element ? element.current : document?.body
  const position = target.getBoundingClientRect()

  return { x: position.left, y: position.top }
}

export function useScrollPosition(effect, deps, element, wait) {
  const position = useRef(getScrollPosition({}))

  let throttleTimeout = null

  const callBack = () => {
    const currPos = getScrollPosition({ element })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    window.addEventListener("scroll", handleScroll, { capture: false, passive: true })

    return () => window.removeEventListener("scroll", handleScroll)
  }, deps)
}
