import React from "react"
import tw from "twin.macro"

import { StyledButton } from "../../styled/Button"
import { Link } from "gatsby"
import { P } from "../../styled/Text"

const StyledContainer = tw.div`inline-block mr-4 xl:mr-8`
export const Text = tw(P)`mb-0.5 text-xxxs font-medium text-center text-black`
const Button = tw(StyledButton)`inline-block m-auto w-auto py-2 px-8 lg:text-xs`

export const UpgradeButton = ({ ...props }): JSX.Element => {
  return (
    <StyledContainer>
      <Text>You're on our free plan</Text>
      <Button as={Link} to="/memberships" size="large" theme="primary" colour="dark" {...props}>
        Upgrade
      </Button>
    </StyledContainer>
  )
}
