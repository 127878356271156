import tw, { styled } from "twin.macro"

const styles = {
  size: {
    xs: tw`w-2`,
    s: tw`w-3`,
    m: tw`w-4`,
    ml: tw`w-5`,
    l: tw`w-8`,
    xl: tw`w-12`,
    xxl: tw`w-16`,
  },
  disc: {
    xs: tw`h-8 w-8`,
    s: tw`h-12 w-12`,
    m: tw`h-12 w-12`,
    ml: tw`h-16 w-16`,
    l: tw`h-20 w-20`,
    xl: tw`h-27 w-27`,
    xxl: tw`h-32 w-32`,
  },
  colour: {
    primary: tw`text-pink`,
    secondary: tw`text-green`,
    tertiary: tw`text-grey-mid`,
    grey: tw`text-grey-light`,
    white: tw`text-white`,
    greyDark: tw`text-grey-dark`,
  },
  background: {
    grey: tw`bg-grey-pale`,
    white: tw`bg-white`,
  },
  state: {
    asblock: tw`block`,
    inbutton: tw`absolute top-0 bottom-0 left-0 ml-3`,
  },
}

export const StyledIcon = styled.span<{
  state: string
  colour: string
  size: string
}>`
  ${tw`select-none inline-block`}
  ${({ state }) => (state ? styles.state[state] : null)}
  > svg {
    ${tw`fill-current h-full`}
    ${({ colour }) => (colour ? styles.colour[colour] : null)}
    ${({ size }) => (size ? styles.size[size] : null)}
  }
`

export const StyledDiscIcon = styled(StyledIcon)<{
  background: string
  size: string
}>`
  ${tw`rounded-full flex items-center justify-center`}
  ${({ background }) => (background ? styles.background[background] : styles.background[`grey`])}
  ${({ size }) => (size ? styles.disc[size] : null)}
`
