import React, { useMemo, useCallback } from "react"

import { useApp } from "../../hooks/useApp"
import { useStripe } from "../../hooks/useStripe"

export const withPopupUpgrade = Component =>
  //@ts-ignore
  React.memo(({ name = "PopupUpgrade" }) => {
    const { customer, member, activePopup, setActivePopup } = useApp()
    const { openCheckout, plans, loading } = useStripe()

    const handlePopup = useCallback(show => setActivePopup(show), [setActivePopup])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          title={""}
          content={""}
          customer={customer?.email?.length}
          level={member?.membership?.type}
          plans={plans}
          activePopup={activePopup}
          handlePopup={handlePopup}
          handleCheckout={openCheckout}
          loading={loading}
        />
      ),
      [member, customer, activePopup, loading]
    )
  })
