import React, { useState, useEffect, useCallback } from "react"
import { navigate } from "gatsby"

export const AppContext: any = React.createContext({})

export const AppProvider = ({ children, config: siteConfig }): JSX.Element => {
  const [config] = useState(siteConfig || {})
  const [route, setRoute] = useState("")
  const [loaded, setLoaded] = useState([])
  const [ready, setReady] = useState(false)
  const [customer, setCustomer] = useState(null)
  const [member, setMember] = useState(false)
  const [requests, setRequests] = useState([])
  const [wishlist, setWishlist] = useState([])
  const [redirectUrl, setRedirectUrl] = useState("")
  const [fixed, setFixed] = useState(false)
  const [activeMenu, setActiveMenu] = useState(false)
  const [activePopup, setActivePopup] = useState(false)
  const [activeCompleteProfile, setActiveCompleteProfile] = useState(false)
  const [activeAlreadyBooked, setActiveAlreadyBooked] = useState(false)
  const [activeVideo, setActiveVideo] = useState(false)
  const [activeSearch, setActiveSearch] = useState(false)
  const [activeSubscribe, setActiveSubscribe] = useState(false)
  const [unReviewMentors, setUnReviewedMentors] = useState([])

  useEffect(() => {
    const waiting = ["customer", "member"]
    if (JSON.stringify(waiting.sort()) === JSON.stringify(loaded.sort())) {
      setReady(true)
      if (customer?.id && typeof window !== "undefined") {
        if (redirectUrl?.length) {
          navigate(redirectUrl)
          // setTimeout(() => setRedirectUrl(""), 1000)
        }
      }
    }
  }, [loaded])

  const handleLoaded = useCallback(
    type => {
      if (type === "reset") setLoaded([])
      if (type !== "reset") setLoaded(prevState => (!prevState.some(value => type.includes(value)) ? [...prevState, ...type] : prevState))
    },
    [loaded, setLoaded]
  )

  const handleLoadedReset = useCallback(
    items => {
      setLoaded(items)
    },
    [setLoaded]
  )

  const values = {
    config,
    ready,
    setReady,
    loaded,
    setLoaded: handleLoaded,
    resetLoaded: handleLoadedReset,
    route,
    setRoute,
    fixed,
    setFixed,
    redirectUrl,
    setRedirectUrl,
    customer,
    setCustomer,
    member,
    setMember,
    requests,
    setRequests,
    wishlist,
    setWishlist,
    activeMenu,
    setActiveMenu,
    activePopup,
    setActivePopup,
    activeCompleteProfile,
    setActiveCompleteProfile,
    activeAlreadyBooked,
    setActiveAlreadyBooked,
    activeVideo,
    setActiveVideo,
    activeSearch,
    setActiveSearch,
    activeSubscribe,
    setActiveSubscribe,
    unReviewMentors,
    setUnReviewedMentors,
  }

  return <AppContext.Provider value={{ ...values }}>{children}</AppContext.Provider>
}

export const withApp = Component => (props): JSX.Element => (
  <AppContext.Consumer>{values => <Component {...props} layout={values} />}</AppContext.Consumer>
)
