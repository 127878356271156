import tw, { styled } from "twin.macro"

const styles = {
  default: tw`flex items-start justify-between w-full`,
}

export const StyledRow = styled.div<{
  center: boolean
  reverse: boolean
  stretch: boolean
  wrap: boolean
}>`
  ${styles.default}
  ${({ center }) => center && tw`items-center`}
  ${({ reverse }) => reverse && tw`flex-row-reverse`}
  ${({ stretch }) => stretch && tw`items-stretch`}
  ${({ wrap }) => wrap && tw`flex-wrap`}
`
