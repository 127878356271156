import { useApp } from "./useApp"
import CryptoJS from "crypto-js"

export const useFront = () => {
  const {
    config: {
      services: {
        front: { verificationSecret, chatId },
      },
    },
  } = useApp()

  const updateChat = customer => {
    if (!window.FrontChat) return
    if (customer?.email) {
      const hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, verificationSecret)
      hmac.update(customer.email)

      const userHash = hmac.finalize().toString(CryptoJS.enc.Hex)

      window.FrontChat("init", {
        chatId: chatId,
        name: customer.firstName,
        email: customer.email,
        userHash,
      })
    } else {
      window.FrontChat("shutdown", { clearSession: true })
    }
  }
  return { updateChat }
}
