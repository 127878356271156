import React from "react"

import { withSocialLinks } from "./withSocialLinks"
import { List, Item, Link } from "./SocialLinks.styled"
import { Icon } from "../Icon/Icon"

export const SocialLinks = withSocialLinks(
  ({ items = [], centered = false, size, disc, wrap, menu }: Props): JSX.Element => (
    <List centered={centered} disc={disc} wrap={wrap} menu={menu}>
      {items?.map((item, index) => (
        <Item key={index}>
          <Link href={item?.url} title={item?.title} target={item?.title !== "Email" && "_blank"} disc={disc} wrap={wrap}>
            <Icon name={item?.icon} size={size} disc={disc} />
          </Link>
        </Item>
      ))}
    </List>
  )
)

export interface Props {
  items: Array<{
    url: string
    title: string
    icon: string
  }>
  size: string
  centered: boolean
  disc: boolean
  wrap: boolean
  menu?: boolean
}
