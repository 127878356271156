import { useStaticQuery, graphql } from "gatsby"

export const useSettings = () =>
  useStaticQuery(graphql`
    query SANITY_SETTINGS {
      announcements: sanitySettingAnnouncement {
        duration
        announcements {
          title
          link {
            link
            external
          }
        }
      }
      appearance: sanitySettingAppearance {
        themeDisplay
        themeColour {
          hex
        }
        themeBgColour {
          hex
        }
      }
      forms: allSanitySettingForms {
        edges {
          node {
            id: _id
            title
            slug {
              current
            }
            subject
            types {
              title
              recipients
              options
            }
          }
        }
      }
      languages: sanitySettingLanguages {
        hrefLangs: languages {
          _key
          language
          region
          url
        }
      }
      maintenance: sanitySettingMaintenance {
        title
        password
        enabled
        content
        backgroundImage {
          asset {
            url
          }
        }
      }
      membership: sanitySettingMembership {
        enabled
      }
      social: sanitySettingSocial {
        instagramAccessToken
        instagramClientID
        instagramUsername
        channels {
          title
          url
        }
      }
      scripts: sanitySettingScripts {
        trackingBody {
          code
        }
        trackingFooter {
          code
        }
        trackingHeader {
          code
        }
      }
      preferences: sanitySettingPreferences {
        url
        title
        description
        separator
        logo {
          asset {
            url
          }
        }
        location
        language
        author
      }
      organisation: sanitySettingOrganisation {
        title
        description
        author
        logo {
          asset {
            url
          }
        }
        addressLocality
        addressRegion
        postalCode
        streetAddress
        separator
        url
      }
      tracking: sanitySettingTracking {
        googleTagManagerId
        googleSiteVerify
        googleAnalyticsId
        facebookPixelId
        facebookAppId
      }
      menus: sanitySettingMenus {
        header: _rawHeader(resolveReferences: { maxDepth: 10 })
        footer: _rawFooter(resolveReferences: { maxDepth: 10 })
        account: _rawAccount(resolveReferences: { maxDepth: 10 })
      }
      memberships: allSanityMembership {
        edges {
          node {
            ...GatsbyMembershipFragment
          }
        }
      }
    }
  `)
